import * as types from './mutation-types';

export default {
  logout({ commit }) {
    commit(types.LOGOUT);
  },
  async fetchSummary({ rootState }, { body, summaryType }) {
    const service = this.$services.brainClient;
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchSummary(
          rootState.accessToken,
          body,
          summaryType,
        )) {
          yield chunk;
        }
      },
    };
  },

  async fetchActionItems({ rootState }, { body, abortController }) {
    const service = this.$services.brainClient;
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchActionItems(
          rootState.accessToken,
          body,
          abortController,
        )) {
          yield chunk;
        }
      },
    };
  },

  async fetchAsk({ rootState }, { queryId, queryBody, dev, askInterface }) {
    const service = this.$services.brainClient;
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.askStream(
          rootState.accessToken,
          queryId,
          queryBody,
          dev,
          askInterface,
        )) {
          yield chunk;
        }
      },
    };
  },

  sendAskFeedback({ rootState }, { queryId, queryBody, feedback }) {
    return this.$services.brainClient.sendAskFeedback(
      rootState.accessToken,
      queryId,
      queryBody,
      feedback,
    );
  },
  sendAskBetaFeedback({ rootState }, { queryId, queryBody, feedback }) {
    return this.$services.brainClient.sendAskBetaFeedback(
      rootState.accessToken,
      queryId,
      queryBody,
      feedback,
    );
  },
  async fetchTranslation({ rootState }, { text, language, abortController }) {
    const service = this.$services.brainClient;
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchTranslation(
          rootState.accessToken,
          text,
          language,
          abortController,
        )) {
          yield chunk;
        }
      },
    };
  },

  async fetchCorrection(
    { rootState },
    { text, abortController, correctionType },
  ) {
    const service = this.$services.brainClient;
    return {
      async *[Symbol.asyncIterator]() {
        for await (const chunk of service.fetchCorrection(
          rootState.accessToken,
          text,
          correctionType,
          abortController,
        )) {
          yield chunk;
        }
      },
    };
  },

  async getArticleToGuideSuggestion(_, { label, body, language }) {
    return this.$services.brainClient.getArticleToGuideSuggestion(
      label,
      body,
      language,
    );
  },
  async postTryOutQuestions(_, { lang, n }) {
    const ans = await this.$services.brainClient.postTryOutQuestions({
      lang,
      n,
    });
    if (!ans && !ans.data && !ans.data.questions) return [];
    return ans.data.questions;
  },

  async postArticleToGuide(
    _,
    {
      objective,
      label,
      body,
      language,
      knowledge,
      knowledgeId,
      caseParameters,
    },
  ) {
    return this.$services.brainClient.postArticleToGuide(
      objective,
      label,
      body,
      language,
      knowledge,
      knowledgeId,
      caseParameters,
    );
  },

  analyzeCustomizationParameters({ rootState }, { queryId, textSpec }) {
    return this.$services.brainClient.analyzeCustomizationParameters(
      rootState.accessToken,
      queryId,
      textSpec,
    );
  },

  getCustomization({ rootState }, { queryId }) {
    return this.$services.brainClient.getCustomization(
      rootState.accessToken,
      queryId,
    );
  },

  setCustomization({ rootState }, { queryId, customization }) {
    return this.$services.brainClient.setCustomization(
      rootState.accessToken,
      queryId,
      customization,
    );
  },

  delCustomization({ rootState }, { queryId }) {
    return this.$services.brainClient.delCustomization(
      rootState.accessToken,
      queryId,
    );
  },

  async getArticleToGuideStatus(_, { processId }) {
    return this.$services.brainClient.getArticleToGuideStatus(processId);
  },

  async cancelArticleToGuide(_, { processId }) {
    return this.$services.brainClient.cancelArticleToGuide(processId);
  },

  updateArticleToGuideProcess({ commit }, { processes }) {
    commit(types.UPDATE_ARTICLE_TO_GUIDE_PROCESSES, processes);
  },

  // ASK IN SEARCH

  setDisplayAskInSearch({ commit }, payload) {
    commit(types.SET_DISPLAY_ASK_IN_SEARCH, payload);
  },
  setLastAskQuery({ commit }, { query, answer }) {
    commit(types.SET_LAST_ASK_QUERY, { query, answer });
  },
};
