import data from './user.data.raw.js';

export class UserServiceData {
  constructor(client, axiosClient) {
    this.client = client;
    this.axiosClient = axiosClient;
  }

  /**
   * CREATE METHODS
   */

  register(args) {
    return this.axiosClient.post('/api/users/register', args);
  }

  createUsersInvitation(users, page, pageSize, filters, sort, search) {
    return this.client
      .mutate({
        mutation: data.createUsersInvitation,
        variables: {
          users,
          page,
          pageSize,
          filters,
          sort,
          search,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.createUsersInvitation)
      .catch((error) => error);
  }

  renewUserInvitations(invitedUsers, page, pageSize, filters, sort, search) {
    return this.client
      .mutate({
        mutation: data.renewUserInvitations,
        variables: {
          invitedUsers,
          page,
          pageSize,
          filters,
          sort,
          search,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.renewUserInvitations)
      .catch((error) => error);
  }

  verifyToken({ token, tokenType }) {
    return this.client
      .mutate({
        mutation: data.verifyToken,
        variables: { token, tokenType },
      })
      .then((response) => response.data.verifyToken)
      .catch((error) => error);
  }

  createUserFromInvitation(args) {
    return this.client
      .mutate({
        mutation: data.createUserFromInvitation,
        variables: {
          ...args,
        },
      })
      .then((response) => response.data.createUserFromInvitation)
      .catch((error) => error);
  }

  sendFeedback(feedback) {
    return this.axiosClient.post('/integration/feedback', feedback);
  }

  addFavorite(entity) {
    return this.axiosClient.post('/integration/add-favorite', entity);
  }

  removeFavorite(entity) {
    return this.axiosClient.post('/integration/remove-favorite', entity);
  }

  /**
   * READ METHODS
   */

  checkUserValidity(email) {
    return this.client
      .query({
        query: data.checkUserValidity,
        variables: {
          email,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.checkUserValidity)
      .catch((error) => error);
  }

  retrieveUserHelpdeskId(id, helpdesk, page, pageSize, filters, sort, search) {
    return this.client
      .mutate({
        mutation: data.retrieveUserHelpdeskId,
        variables: {
          id,
          helpdesk,
          page,
          pageSize,
          filters,
          sort,
          search,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.retrieveUserHelpdeskId)
      .catch((error) => error);
  }

  getUserInfos() {
    return this.axiosClient.get('/integration/login-with-token');
  }

  getUsersWithPermissions(permissions, knowledgeId) {
    return this.client
      .query({
        query: data.getUsersWithPermissions,
        variables: { permissions, knowledgeId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.usersWithPermissions;
      })
      .catch((error) => error);
  }
  searchEditorUsers(page, pageSize, filters, sort, search, permissions) {
    return this.client
      .query({
        query: data.searchEditorUsers,
        variables: { page, pageSize, filters, sort, search, permissions },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.usersWithPermissions;
      })
      .catch((error) => error);
  }

  getUserById(id) {
    return this.client
      .query({
        query: data.getUserById,
        variables: { id },
      })
      .then((response) => {
        return response.data.getUser;
      })
      .catch((error) => error);
  }

  getUserOrDeletedUserById(id) {
    return this.client
      .query({
        query: data.getUserOrDeletedUserById,
        variables: { id },
      })
      .then((response) => {
        return response.data.getUserOrDeletedUserById;
      })
      .catch((error) => error);
  }

  getMe() {
    return this.client
      .query({
        query: data.getMe,
      })
      .then((response) => {
        return response.data.currentUser;
      })
      .catch((error) => error);
  }

  getUserFromAccessToken() {
    return this.client
      .query({
        query: data.getUserFromAccessToken,
      })
      .then((response) => {
        return response.data.getUserInformations;
      })
      .catch((error) => error);
  }

  /**
   * UPDATE METHODS
   */

  updateManyUsersGroup(
    userIds,
    groupIds,
    page,
    pageSize,
    filters,
    sort,
    search,
    { skipEmployees = true, skipPendingEmployees = true },
  ) {
    return this.client
      .mutate({
        mutation: data.setManyUsersGroup,
        variables: {
          userIds,
          groupId: groupIds,
          page,
          pageSize,
          filters,
          sort,
          search,
          skipEmployees,
          skipPendingEmployees,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.setUsersGroup)
      .catch((error) => error);
  }

  updateManyUsersRole(
    userIds,
    customRoleIds,
    page,
    pageSize,
    filters,
    sort,
    search,
    { skipEmployees = true, skipPendingEmployees = true },
  ) {
    return this.client
      .mutate({
        mutation: data.setManyUsersRole,
        variables: {
          userIds,
          customRoleIds,
          page,
          pageSize,
          filters,
          sort,
          search,
          skipEmployees,
          skipPendingEmployees,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.setUsersRole)
      .catch((error) => error);
  }

  updateManyUsersLabels(
    userIds,
    companyUserLabelIds,
    page,
    pageSize,
    filters,
    sort,
    search,
    { skipEmployees = true, skipPendingEmployees = true },
  ) {
    return this.client
      .mutate({
        mutation: data.setManyUsersLabels,
        variables: {
          userIds,
          companyUserLabelIds,
          page,
          pageSize,
          filters,
          sort,
          search,
          skipEmployees,
          skipPendingEmployees,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.setUsersLabels)
      .catch((error) => error);
  }

  updateUser(user) {
    return this.client
      .mutate({
        mutation: data.updateUser,
        variables: {
          user,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.updateUser)
      .catch((error) => error);
  }

  updateUserSettings({ username, email, jobtitle, password }) {
    return this.client
      .mutate({
        mutation: data.updateUserSettings,
        variables: {
          username,
          email,
          jobtitle,
          password,
        },
      })
      .then((response) => response.data.updateSettings)
      .catch((error) => error);
  }

  resetPasswordFromAdmin(userId) {
    return this.client
      .mutate({
        mutation: data.resetPasswordFromAdmin,
        variables: { userId },
      })
      .then((response) => response.data.resetPasswordFromAdmin)
      .catch((error) => error);
  }

  resetPassword(email) {
    return this.client
      .mutate({
        mutation: data.resetPassword,
        variables: { email },
      })
      .then((response) => response.data.resetPassword)
      .catch((error) => error);
  }

  /**
   * DELETE METHODS
   */

  deleteManyUsers(
    userIds,
    page,
    pageSize,
    filters,
    sort,
    search,
    { skipEmployees = true, skipPendingEmployees = true },
  ) {
    return this.client
      .mutate({
        mutation: data.deleteManyUsers,
        variables: {
          ids: userIds,
          page,
          pageSize,
          filters,
          sort,
          search,
          skipEmployees,
          skipPendingEmployees,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.deleteUsers)
      .catch((error) => error);
  }
}
