import * as types from './mutation-types';

export default {
  async triggerContentTranslation(
    _,
    { documentId, sourceLanguage, targetLanguage },
  ) {
    if (!documentId || !sourceLanguage || !targetLanguage) return;

    const lockId = await this.$services.brainClient.triggerContentTranslation({
      documentId,
      sourceLanguage,
      targetLanguage,
    });
    if (!lockId) return;
    return lockId;
  },

  async getTranslationStatus({ getters, commit }, { lockId, targetLanguage }) {
    const { focusContent } = getters;
    if (!lockId) return;
    const status = await this.$services.brainClient.getTranslationStatus(
      lockId,
    );
    if (!status) return;

    if (status === 'done') {
      focusContent.setLanguages.push(targetLanguage);
      commit(types.SET_FOCUS_CONTENT, focusContent);
    }

    return status;
  },

  async getLoadingLanguages(_, { documentId }) {
    if (!documentId) return [];
    return this.$services.parametricContents.getLoadingLanguages(documentId);
  },
};
