<template>
  <div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.title.label') }}
      </h4>
      <el-input
        v-model="notification.label"
        :placeholder="$t('knowledge.modals.notifications.title.placeholder')"
        @change="onKeyupEnterSubject"
        @keyup.tab="onKeyupEnterSubject"
      />
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.message.label') }}
      </h4>
      <MaydayNotificationEditor
        ref="NotificationEditor"
        class="notification__editor"
        :message="notification.body"
        :editable="true"
        @input="notification.body = $event"
      />
    </div>
    <div class="d-flex flex-column w-100 mb-1">
      <div class="d-flex align-items-center justify-content-between w-100 mb-1">
        <h4 class="section-title mb-0">
          {{ $t('knowledge.modals.notifications.attachment') }}
        </h4>
        <AddAttachment
          v-if="true"
          :disabled="localFileInputDisable"
          :attachment-loading="attachmentLoading"
          :showLoader="false"
          @new-attachment="handleFileChange"
          @disable-click="showDisableFileUpload(disableMessage)"
          ref="add-attachment"
          class="editor-send-wrapper"
        >
          <template v-slot:slot-button>
            <div
              class="add-button"
              :class="
                localFileInputDisable
                  ? 'add-button__disable'
                  : 'add-button__enable'
              "
            >
              <div v-if="!attachmentLoading">
                {{ $t('knowledge.modals.notifications.add') }}
              </div>
              <font-awesome-icon
                v-else
                :icon="['fas', 'spinner-third']"
                spin
              ></font-awesome-icon>
            </div>
          </template>
        </AddAttachment>
      </div>

      <AttachmentList
        :attachments="attachments"
        :isInput="true"
        :displayDisableFileUpload="displayDisableFileUpload"
        :errorText="errorText"
        :displayHeader="false"
        :attachmentWidth="'49%'"
        @delete-attachment="handleDeleteAttachment"
        @disable-click="showDisableFileUpload()"
      ></AttachmentList>
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.tags.label') }}
      </h4>
      <el-input
        :placeholder="$t('knowledge.modals.notifications.tags.placeholder')"
        v-model="newTag"
        @keyup.native.enter="addTagToList"
      />
      <div class="tags-container ml-2">
        <el-tag
          v-for="(tag, index) in notification.tags"
          :key="index"
          closable
          @close="handleCloseTag(index)"
          class="mt-2 mr-2"
          >{{ tag }}</el-tag
        >
      </div>
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.groups.label') }}
      </h4>
      <el-select
        v-model="notification.targetGroupIds"
        multiple
        :placeholder="$t('knowledge.modals.notifications.groups.placeholder')"
        style="width: 100%"
      >
        <el-option
          v-for="group in groups"
          :key="group.id"
          :label="group.name"
          :value="group.id"
        ></el-option>
      </el-select>
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.urgent.label') }}
      </h4>
      <el-switch v-model="notification.urgent" class="ml-2"></el-switch>
    </div>
    <div class="section" v-if="nodeId">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.linked-content.label') }}
      </h4>
      <el-switch v-model="notification.link" class="ml-2"></el-switch>
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.publication-date-start') }}
        <el-tooltip
          class="ml-1"
          :content="$t('knowledge.modals.notifications.timezone')"
          placement="right"
        >
          <font-awesome-icon :icon="['fal', 'info-circle']" />
        </el-tooltip>
      </h4>
      <div class="d-flex align-items-center justify-content-between">
        <el-date-picker
          :style="dateInputStyleWidth"
          v-model="dateStart"
          type="date"
          :placeholder="$t('knowledge.modals.notifications.date-placeholder')"
          format="dd/MM/yyyy"
          :picker-options="startDatePickerOptions"
        ></el-date-picker>
        <el-time-select
          :style="dateInputStyleWidth"
          v-model="timeStart"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '23:30',
          }"
          :disabled="!dateStart"
          :placeholder="$t('knowledge.modals.notifications.time-placeholder')"
        >
        </el-time-select>
      </div>
      <span v-if="errorDateStartBeforeNow" class="error-message">
        {{ $t('knowledge.modals.notifications.errors.date-start-before-now') }}
      </span>
    </div>
    <div class="section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.publication-date-end') }}
        <el-tooltip
          class="ml-1"
          :content="$t('knowledge.modals.notifications.timezone')"
          placement="right"
        >
          <font-awesome-icon :icon="['fal', 'info-circle']" />
        </el-tooltip>
      </h4>
      <div class="d-flex align-items-center justify-content-between">
        <el-date-picker
          :style="dateInputStyleWidth"
          v-model="dateEnd"
          type="date"
          :placeholder="$t('knowledge.modals.notifications.date-placeholder')"
          format="dd/MM/yyyy"
          :picker-options="endDatePickerOptions"
        ></el-date-picker>
        <el-time-select
          :style="dateInputStyleWidth"
          v-model="timeEnd"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '23:30',
          }"
          :disabled="!dateEnd"
          :placeholder="$t('knowledge.modals.notifications.time-placeholder')"
        >
        </el-time-select>
      </div>
      <span v-if="errorDateEndBeforeDateStart" class="error-message">
        {{
          $t('knowledge.modals.notifications.errors.date-end-before-date-start')
        }}
      </span>
      <span v-if="errorDateEndBeforeNow" class="error-message">
        {{ $t('knowledge.modals.notifications.errors.date-end-before-now') }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MaydayNotificationEditor from '@/components/Editors/MaydayNotificationEditor';

import AddAttachment from '@/components/Attachments/AddAttachment';
import AttachmentList from '@/components/Attachments/AttachmentList';
import AttachmentsMixin from '@/mixins/AttachmentsMixin';

export default {
  name: 'NotificationEdition',
  components: { MaydayNotificationEditor, AttachmentList, AddAttachment },
  props: {
    nodeId: {
      type: String,
      default: null,
    },
    linkContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notification: {
        label: '',
        body: '',
        urgent: false,
        tags: [],
        targetGroupIds: [],
        link: this.linkContent,
        publicationDateStart: '',
        publicationDateEnd: '',
      },
      dateStart: '',
      dateEnd: '',
      timeStart: '',
      timeEnd: '',
      newTag: '',
      startDatePickerOptions: {
        disabledDate(time) {
          const today = new Date();
          const yesterday = today.getTime() - 24 * 60 * 60 * 1000;
          return time.getTime() < yesterday;
        },
      },
      endDatePickerOptions: {
        disabledDate(time) {
          const today = new Date();
          const yesterday = today.getTime() - 24 * 60 * 60 * 1000;
          return time.getTime() < yesterday;
        },
      },
    };
  },
  mixins: [AttachmentsMixin()],
  computed: {
    disableMessage() {
      return this.notification.urgent
        ? 'task-view.task-focus-modal.upload-error.urgent-notif'
        : 'task-view.task-focus-modal.upload-error.disable-file-upload';
    },
    localFileInputDisable() {
      return this.fileInputDisable || this.notification.urgent;
    },
    errorDateEndBeforeDateStart() {
      return (
        !!this.notification.publicationDateStart &&
        !!this.notification.publicationDateEnd &&
        this.notification.publicationDateStart >=
          this.notification.publicationDateEnd
      );
    },
    errorDateStartBeforeNow() {
      return (
        !!this.notification.publicationDateStart &&
        this.notification.publicationDateStart <= Date.now()
      );
    },
    errorDateEndBeforeNow() {
      return (
        !!this.notification.publicationDateEnd &&
        this.notification.publicationDateEnd <= Date.now()
      );
    },
    hasDatesError() {
      return (
        this.errorDateStartBeforeNow ||
        this.errorDateEndBeforeDateStart ||
        this.errorDateEndBeforeNow
      );
    },
    dateInputStyleWidth() {
      return 'width: 192px';
    },
    ...mapGetters('adminModule', ['groups']),
  },
  methods: {
    onKeyupEnterSubject() {
      if (this.notification.label.length > 0) {
        this.$refs['NotificationEditor'].focusEditor();
      }
    },
    handleCloseTag(index) {
      this.notification.tags = this.notification.tags.filter((v, indexV) => {
        return indexV !== index;
      });
    },
    addTagToList() {
      if (!this.newTag) return;
      this.notification.tags.push(this.newTag);
      this.newTag = '';
    },
    attachPublicationDates(dateStart, dateEnd, timeStart, timeEnd) {
      if (dateStart) {
        let start = dateStart.getTime();
        if (timeStart) {
          start += timeStart.split(':')[0] * 60 * 60 * 1000;
          start += timeStart.split(':')[1] * 60 * 1000;
        }
        this.notification.publicationDateStart = start;
      }

      if (dateEnd) {
        let end = dateEnd.getTime();
        if (timeEnd) {
          end += timeEnd.split(':')[0] * 60 * 60 * 1000;
          end += timeEnd.split(':')[1] * 60 * 1000;
        }
        this.notification.publicationDateEnd = end;
      }
    },
    sendChangeInNotification() {
      const sentAttachments = this.attachments.map((at) => at.url);
      this.attachPublicationDates(
        this.dateStart,
        this.dateEnd,
        this.timeStart,
        this.timeEnd,
      );
      this.$emit('change', {
        ...this.notification,
        attachments: sentAttachments,
      });
    },
  },
  watch: {
    notification: {
      handler() {
        this.sendChangeInNotification();
        if (this.notification.urgent && this.attachments.length > 0) {
          this.showDisableFileUpload(
            'task-view.task-focus-modal.upload-error.urgent-notif',
          );
          this.attachments = [];
        }
      },
      deep: true,
    },
    attachments: {
      handler() {
        this.sendChangeInNotification();
      },
      deep: true,
    },
    dateStart: {
      handler(val) {
        if (val) this.timeStart = '09:30';
        else {
          this.notification.publicationDateStart = '';
          this.timeStart = '';
        }
        this.sendChangeInNotification();
      },
    },
    timeStart: {
      handler() {
        this.sendChangeInNotification();
      },
    },
    dateEnd: {
      handler(val) {
        if (val) this.timeEnd = '09:30';
        else {
          this.notification.publicationDateEnd = '';
          this.timeEnd = '';
        }
        this.sendChangeInNotification();
      },
    },
    timeEnd: {
      handler() {
        this.sendChangeInNotification();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  text-align: left;
  margin-bottom: 1rem;
}
.section-title {
  color: $grey-7-mayday;
  text-transform: uppercase;
}

.notification__editor {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
}

.add-button {
  min-width: 57px;
  min-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue-mayday;
  color: white;
  border-radius: 4px;
  font-size: 12px;
  &__disable {
    background-color: $blue-1-mayday;
    &:hover {
      cursor: not-allowed;
    }
  }
  &__enable:hover {
    cursor: pointer;
  }
}

.error-message {
  display: block;
  color: $red-mayday;
  font-size: 12px;
}
</style>
