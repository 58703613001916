import gql from 'graphql-tag';

export default {
  getCompanyTaskStatus: gql`
    query getStatusesAndFirstTasks($filters: TaskFilters) {
      CompanyTaskStatus: getStatusesAndFirstTasks(filters: $filters) {
        statuses {
          id
          name
          order
          publicId
        }
        tasks {
          key
          value {
            total
            page
            pages
            results {
              id
              author {
                id
                username
              }
              assignee {
                __typename
                ... on CustomRole {
                  id
                  name
                }
                ... on SafeUser {
                  id
                  username
                }
              }
              initialReceiver {
                __typename
                ... on CustomRole {
                  id
                  name
                }
                ... on SafeUser {
                  id
                  username
                }
              }
              companyTaskStatusId
              title
              body
              type
              entityType
              entityId
              lang
              createdAt
              source
              upvoteCount
              commentCount
              upvotes {
                id
                username
              }
              knowledgeId
              upvotes {
                id
                username
              }
              rating {
                average
              }
              attachments
              customName
              customIcon
            }
          }
        }
      }
    }
  `,

  getCompanyTasks: gql`
    query getCompanyTasks($statusId: ID!, $filters: TaskFilters, $page: Int) {
      CompanyTasks: getCompanyTasks(
        statusId: $statusId
        filters: $filters
        page: $page
      ) {
        page
        results {
          id
          author {
            id
            username
          }
          assignee {
            __typename
            ... on CustomRole {
              id
              name
            }
            ... on SafeUser {
              id
              username
            }
          }
          initialReceiver {
            __typename
            ... on CustomRole {
              id
              name
            }
            ... on SafeUser {
              id
              username
            }
          }
          companyTaskStatusId
          title
          body
          type
          entityType
          entityId
          lang
          createdAt
          source
          upvoteCount
          commentCount
          upvotes {
            id
            username
          }
          knowledgeId
          upvotes {
            id
            username
          }
          rating {
            average
          }
          attachments
        }
      }
    }
  `,

  getCompanyTaskById: gql`
    query getCompanyTaskById($id: ID!, $skipModalFields: Boolean!) {
      CompanyTask: getCompanyTaskById(id: $id) {
        id
        publicId
        author {
          id
          username
          groups @skip(if: $skipModalFields) {
            name
            id
          }
          labels @skip(if: $skipModalFields) {
            name
            id
            companyUserLabelCategoryId
          }
        }
        assignee {
          __typename
          ... on CustomRole {
            id
            name
          }
          ... on SafeUser {
            id
            username
          }
        }
        initialReceiver {
          __typename
          ... on CustomRole {
            id
            name
          }
          ... on SafeUser {
            id
            username
          }
        }
        companyTaskStatusId
        title
        body
        type
        entityType
        entityId
        lang
        knowledgeId
        createdAt
        source
        upvoteCount
        commentCount
        upvotes {
          id
          username
        }
        isArchive
        comment @skip(if: $skipModalFields) {
          id
          comment
          createdAt
          updatedAt
          author {
            id
            username
          }
          attachments
          isInternal
        }
        rating {
          average
          currentUserRating
        }
        attachments
        customName
        customIcon
      }
    }
  `,

  setCompanyTaskStatus: gql`
    mutation setStatus($id: ID!, $companyTaskStatusId: ID!) {
      changedRows: setStatus(id: $id, companyTaskStatusId: $companyTaskStatusId)
    }
  `,

  setAssignee: gql`
    mutation setAssignee($id: ID!, $assigneeId: ID!) {
      changedRows: setAssignee(id: $id, assigneeId: $assigneeId)
    }
  `,

  bulkSetIsArchive: gql`
    mutation bulkSetIsArchive($ids: [ID!], $isArchive: Boolean!) {
      changedRows: bulkSetIsArchive(ids: $ids, isArchive: $isArchive)
    }
  `,

  upvoteTask: gql`
    mutation addTaskUpvoter($id: ID!) {
      upvotedTask: addTaskUpvoter(id: $id)
    }
  `,

  removeUpvoteTask: gql`
    mutation removeTaskUpvoter($id: ID!) {
      upvotedTask: removeTaskUpvoter(id: $id)
    }
  `,

  newTaskComment: gql`
    mutation createTaskComment(
      $taskId: ID!
      $comment: String
      $attachments: [String]
      $mentions: [MentionInput!]
      $isInternal: Boolean = false
    ) {
      newTaskComment: createTaskComment(
        taskId: $taskId
        comment: $comment
        attachments: $attachments
        mentions: $mentions
        isInternal: $isInternal
      ) {
        id
        author {
          id
          username
        }
        comment
        createdAt
        updatedAt
        attachments
        mentions
        isInternal
      }
    }
  `,
  deleteTaskComment: gql`
    mutation deleteTaskComment($id: ID!) {
      isDeleted: deleteTaskComment(id: $id)
    }
  `,
  updateTaskComment: gql`
    mutation updateTaskComment(
      $id: ID!
      $comment: String
      $attachments: [String]
      $mentions: [MentionInput!]
    ) {
      updatedTaskComment: updateTaskComment(
        id: $id
        comment: $comment
        attachments: $attachments
        mentions: $mentions
      )
    }
  `,

  getBacklogTasksCount: gql`
    query getBacklogTasksCount {
      backlogTasksCount: getBacklogTasksCount
    }
  `,

  setTaskRating: gql`
    mutation setTaskRating($taskId: ID!, $rating: Int!) {
      setTaskRating(taskId: $taskId, rating: $rating) {
        average
        currentUserRating
      }
    }
  `,

  deleteTaskRating: gql`
    mutation deleteTaskRating($taskId: ID!) {
      deleteTaskRating(taskId: $taskId) {
        average
        currentUserRating
      }
    }
  `,

  getSettingsTaskViews: gql`
    query getSettingsTaskViews($page: Int) {
      getSettingsTaskViews(page: $page) {
        page
        pages
        results {
          id
          name
          description
          filters
        }
      }
    }
  `,

  getTaskViews: gql`
    query getTaskViews {
      getTaskViews {
        id
        name
        description
      }
    }
  `,

  getTaskView: gql`
    query getTaskView($id: ID!) {
      taskView(id: $id) {
        id
        name
        description
        filters
      }
    }
  `,

  createTaskView: gql`
    mutation createTaskView($input: TaskViewInput) {
      createTaskView(input: $input) {
        id
        name
        description
        filters
      }
    }
  `,

  updateTaskView: gql`
    mutation updateTaskView($input: TaskViewInput) {
      updateTaskView(input: $input) {
        id
        name
        description
        filters
      }
    }
  `,

  deleteTaskView: gql`
    mutation deleteTaskView($id: ID!) {
      deleteTaskView(id: $id)
    }
  `,
};
