import { handleCancelService } from '@/adapters/axiosClient';

export class WorkflowAnalyticServiceData {
  constructor(analyticsClient) {
    this.analyticsClient = analyticsClient;
    this.handleCancelService = handleCancelService;
    this.workflowMetricsCancelService = null;

  }

  getWorkflowMetrics(params) {
    this.workflowMetricsCancelService = this.handleCancelService(
      this.workflowMetricsCancelService,
    );
    return this.analyticsClient
      .get('/workflow/metrics', {
        params,
        cancelToken: this.workflowMetricsCancelService.token,
      })
      .then((res) => res.data);
  }
  
}
