import * as types from '../mutation-types';

export const getWorkflowMetrics = async function ({ commit, getters }) {
  commit(types.SET_WORKFLOW_METRICS_IS_LOADING, true);
  commit(
    types.SET_WORKFLOW_METRICS,
    await this.$services.workflowAnalytics.getWorkflowMetrics(
      getters.queryParamsFromFilters,
    ),
  );
  commit(types.SET_WORKFLOW_METRICS_IS_LOADING, false);
};

export const initWorkflowMetrics = async function ({ commit }) {
  commit(types.SET_WORKFLOW_METRICS_IS_LOADING, true);
  commit(types.SET_WORKFLOW_METRICS, {
    workflowMetrics: {
      allStatus: {},
      requestsDuration: [],
    },
  });
  commit(types.SET_WORKFLOW_METRICS_IS_LOADING, false);
};
