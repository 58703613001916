/* eslint-disable no-extra-boolean-cast */
import {
  getCollapsableItems,
  isMonolingual,
} from './commons/GetCollapseLanguages';

function resolvePath(path, context) {
  if (!path.length) return null;
  const key = path[0];

  if (context[key] === undefined || context[key] === null) return context[key];
  if (path.length === 1) return String(context[key]);

  return resolvePath(path.slice(1), context[key]);
}

function getValue(focusCompanyPublicConfig, operations, key, path) {
  if (operations) {
    const keyIdx = operations.findIndex((op) => op.key === key);
    if (keyIdx > -1 && operations[keyIdx].value)
      return operations[keyIdx].value;
    if (keyIdx > -1 && operations[keyIdx].operationType === 'delete') return;
  }
  const pathAsArray = path.split('.');
  const oldElementValue = pathAsArray.reduce((acc, val) => {
    if (acc) {
      if (val.includes('=')) {
        const valAsArray = val.split('=');
        const toGetIdx = acc.findIndex(
          (el) => el[valAsArray[0]] === valAsArray[1],
        );
        acc = acc[toGetIdx];
      } else {
        acc = acc[val];
      }
    }
    return acc;
  }, focusCompanyPublicConfig);
  if (oldElementValue) return oldElementValue;
  else return;
}

function getValueForHierarchy(
  focusKnowledge,
  operations,
  key,
  path,
  nestedPath,
) {
  if (operations) {
    const keyIdx = operations.findIndex((op) => op.key === key);
    if (keyIdx > -1 && operations[keyIdx].value)
      return operations[keyIdx].value;
  }

  const formattedKey = key.replace(/([A-Z])/g, '_$1').toUpperCase();
  const keyIdx = focusKnowledge[path].findIndex(
    (el) => el[nestedPath] === formattedKey,
  );

  return keyIdx > -1 ? 'true' : 'false';
}

function getValueForPlugins(focusKnowledge, operations, key, path) {
  if (operations) {
    const keyIdx = operations.findIndex((op) => op.key === key);
    if (keyIdx > -1 && operations[keyIdx].value)
      return operations[keyIdx].value;
  }

  const formattedKey = path.split('.')[0];

  const plugin = focusKnowledge.plugins.find((p) => p.type === formattedKey);

  if (!plugin) return null;

  return resolvePath(path.split('.').slice(1), plugin);
}

export const DIRECTIONS = {
  HIERARCHY_PREFERENCES: 'HIERARCHY_PREFERENCES',
  PLUGINS: 'PLUGINS',
  PUBLIC_CONFIG: 'PUBLIC_CONFIG',
  TRANSLATIONS: 'TRANSLATIONS',
};

export const publicKnowledgeMenuConfiguration = (
  focusCompanyPublicConfig,
  focusKnowledge,
  operations,
) => ({
  appearances: {
    title: 'public-knowledge.appearances.title',
    name: 'appearances',
    subItem: [
      {
        title: 'public-knowledge.appearances.items.brand.title',
        icon: ['fal', 'copyright'],
        name: 'appearances',
        hash: '#brand',
        changingElements: [
          {
            label: 'favIconPath',
            title: 'public-knowledge.appearances.items.brand.favicon',
            description:
              'public-knowledge.appearances.items.brand.favicon-description',
            inputType: 'img',
            path: 'buildConfig.faviconPath',
            emptyPlaceholder: require('assets/upload-placeholder.svg'),
            emptyPlaceholderHover: require('assets/upload-placeholder.gif'),
            deleteWrapperAlign: 'align-items-end',
            size: { width: '24', height: '24' },
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'favIconPath',
              'buildConfig.faviconPath',
            ),
            component: 'ImageInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
            style: {
              padding: '0px',
              width: '24px',
              height: '24px',
            },
          },
          {
            label: 'companyIconUri',
            title: 'public-knowledge.appearances.items.brand.logo',
            description:
              'public-knowledge.appearances.items.brand.logo-description',
            inputType: 'img',
            path: 'buildConfig.env.companyIconUri',
            emptyPlaceholder: require('assets/upload-placeholder.svg'),
            emptyPlaceholderHover: require('assets/upload-placeholder.gif'),
            deleteWrapperAlign: 'align-items-end',
            size: { width: '70', height: '40' },
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'companyIconUri',
              'buildConfig.env.companyIconUri',
            ),
            component: 'ImageInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
            style: {
              backgroundColor: getValue(
                focusCompanyPublicConfig,
                operations,
                'headerColor',
                'uiConfig.headerColor',
              ),
              emptyBackgroundColor: '#f6f9fc',
              borderRadius: getValue(
                focusCompanyPublicConfig,
                operations,
                'customRadius',
                'uiConfig.customRadius',
              ),
            },
            modalTitle: 'public-knowledge.appearances.items.brand.modal-title',
          },
          // {
          //   label: 'mainPicture',
          //   title: 'public-knowledge.appearances.items.brand.main-picture',
          //   description:
          //     'public-knowledge.appearances.items.brand.main-picture-description',
          //   inputType: 'img',
          //   path: 'buildConfig.env.mainPicture',
          //   emptyPlaceholder: require('assets/upload-placeholder.svg'),
          //   emptyPlaceholderHover: require('assets/upload-placeholder.gif'),
          //   size: { width: '80', height: '80' },
          //   value: getValue(
          //     focusCompanyPublicConfig,
          //     operations,
          //     'mainPicture',
          //     'buildConfig.env.mainPicture',
          //   ),
          //   component: 'imageInput',
          // },
        ],
      },
      {
        title: 'public-knowledge.appearances.items.colors-and-borders.title',
        icon: ['fal', 'palette'],
        name: 'appearances',
        hash: '#colors',
        changingElements: [
          {
            label: 'primary',
            title:
              'public-knowledge.appearances.items.colors-and-borders.main-color',
            description:
              'public-knowledge.appearances.items.colors-and-borders.main-color-description',
            inputType: 'color',
            path: 'uiConfig.primary',
            emptyPlaceholder: '#2D50E6',
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'primary',
              'uiConfig.primary',
            ),
            component: 'ColorInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
          },
          {
            label: 'secondary',
            title:
              'public-knowledge.appearances.items.colors-and-borders.secondary-color',
            description:
              'public-knowledge.appearances.items.colors-and-borders.secondary-color-description',
            inputType: 'color',
            path: 'uiConfig.secondary',
            emptyPlaceholder: '#2D50E6',
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'secondary',
              'uiConfig.secondary',
            ),
            component: 'ColorInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
          },
          {
            label: 'headerColor',
            title:
              'public-knowledge.appearances.items.colors-and-borders.header-color',
            description:
              'public-knowledge.appearances.items.colors-and-borders.header-color-description',
            inputType: 'color',
            path: 'uiConfig.headerColor',
            emptyPlaceholder: '#f6f9fc',
            fallback: getValue(
              focusCompanyPublicConfig,
              operations,
              'primary',
              'uiConfig.primary',
            ),
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'headerColor',
              'uiConfig.headerColor',
            ),
            component: 'ColorInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
          },
          {
            label: 'bodyColor',
            title:
              'public-knowledge.appearances.items.colors-and-borders.body-color',
            description:
              'public-knowledge.appearances.items.colors-and-borders.body-color-description',
            inputType: 'color',
            path: 'uiConfig.bodyColor',
            emptyPlaceholder: '#FCFCFF',
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'bodyColor',
              'uiConfig.bodyColor',
            ),
            component: 'ColorInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
          },
          {
            label: 'customRadius',
            title:
              'public-knowledge.appearances.items.colors-and-borders.border-radius',
            description:
              'public-knowledge.appearances.items.colors-and-borders.border-radius-description',
            inputType: 'slider',
            path: 'uiConfig.customRadius',
            emptyPlaceholder: '4px',
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'customRadius',
              'uiConfig.customRadius',
            ),
            component: 'SliderInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
          },
        ],
      },
      {
        title: 'public-knowledge.appearances.items.font.title',
        icon: ['fal', 'text'],
        name: 'appearances',
        hash: '#font',
        changingElements: [
          {
            label: 'font',
            title: 'public-knowledge.appearances.items.font.element-title',
            description: 'public-knowledge.appearances.items.font.description',
            inputType: 'font',
            path: 'uiConfig.font',
            emptyPlaceholder:
              'public-knowledge.appearances.items.font.empty-placeholder',
            choices: [
              { label: 'Lato', value: 'Lato' },
              { label: 'Arial', value: 'Arial' },
              { label: 'OpenSans', value: 'OpenSans' },
              { label: 'Calibri', value: 'Calibri' },
              { label: 'Helvetica', value: 'Helvetica' },
              { label: 'Courier New', value: 'Courier New' },
              { label: 'Verdana', value: 'Verdana' },
              { label: 'Georgia', value: 'Georgia' },
            ],
            styleInjection(value) {
              return `font-family: ${value}`;
            },
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'font',
              'uiConfig.font',
            ),
            component: 'SelectInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
          },
        ],
      },
      ...(!!focusCompanyPublicConfig.knowledgeId
        ? [
            {
              title: 'public-knowledge.appearances.items.layout.title',
              icon: ['fal', 'pager'],
              name: 'appearances',
              hash: '#layout',
              changingElements: [
                {
                  label: 'mainLayout',
                  title:
                    'public-knowledge.appearances.items.layout.main.element-title',
                  description:
                    'public-knowledge.appearances.items.layout.main.description',
                  inputType: 'text',
                  path: 'buildConfig.layout',
                  emptyPlaceholder:
                    'public-knowledge.appearances.items.layout.main.empty-placeholder',
                  choices: [
                    {
                      label:
                        'public-knowledge.appearances.items.layout.main.classic',
                      value: 'classic',
                      trad: true,
                    },
                    {
                      label:
                        'public-knowledge.appearances.items.layout.main.alt',
                      value: 'alt',
                      trad: true,
                    },
                  ],
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'mainLayout',
                    'buildConfig.layout',
                  ),
                  component: 'SelectInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                },
                {
                  label: 'headerLayout',
                  title:
                    'public-knowledge.appearances.items.layout.header.element-title',
                  description:
                    'public-knowledge.appearances.items.layout.header.description',
                  inputType: 'text',
                  path: 'buildConfig.header',
                  emptyPlaceholder:
                    'public-knowledge.appearances.items.layout.header.empty-placeholder',
                  choices: [
                    {
                      label:
                        'public-knowledge.appearances.items.layout.header.classic',
                      value: 'classic',
                      trad: true,
                    },
                    {
                      label:
                        'public-knowledge.appearances.items.layout.header.alt',
                      value: 'alt',
                      trad: true,
                    },
                  ],
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'headerLayout',
                    'buildConfig.header',
                  ),
                  component: 'SelectInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                },
                {
                  label: 'navigationLayout',
                  title:
                    'public-knowledge.appearances.items.layout.navigation.element-title',
                  description:
                    'public-knowledge.appearances.items.layout.navigation.description',
                  inputType: 'text',
                  path: 'buildConfig.navigation',
                  emptyPlaceholder:
                    'public-knowledge.appearances.items.layout.navigation.empty-placeholder',
                  choices: [
                    {
                      label:
                        'public-knowledge.appearances.items.layout.navigation.classic',
                      value: 'classic',
                      trad: true,
                    },
                    {
                      label:
                        'public-knowledge.appearances.items.layout.navigation.alt',
                      value: 'alt',
                      trad: true,
                    },
                  ],
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'navigationLayout',
                    'buildConfig.navigation',
                  ),
                  component: 'SelectInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                },
              ],
            },
          ]
        : []),
      ...(!!focusCompanyPublicConfig.knowledgeId
        ? [
            {
              title: 'public-knowledge.appearances.items.code-custom.title',
              icon: ['fal', 'code'],
              name: 'appearances',
              hash: '#code',
              changingElements: [
                {
                  label: 'footerVisibility',
                  title:
                    'public-knowledge.appearances.items.code-custom.custom-footer.element-title',
                  description:
                    'public-knowledge.appearances.items.code-custom.custom-footer.description',
                  inputType: 'boolean',
                  path: 'buildConfig.footer.visibility',
                  emptyPlaceholder: 'false',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'footerVisibility',
                    'buildConfig.footer.visibility',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                  changingElements: [
                    {
                      label: 'footerHtml',
                      inputType: 'text',
                      path: 'buildConfig.footer.html',
                      title:
                        'public-knowledge.appearances.items.code-custom.custom-footer.footer-html.element-title',
                      description:
                        'public-knowledge.appearances.items.code-custom.custom-footer.footer-html.description',
                      emptyPlaceholder: '',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'footerHtml',
                        'buildConfig.footer.html',
                      ),
                      accept: '.html',
                      fileName: 'mayday-footer.html',
                      component: 'FileInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                    {
                      label: 'footerCss',
                      inputType: 'text',
                      path: 'buildConfig.footer.css',
                      title:
                        'public-knowledge.appearances.items.code-custom.custom-footer.footer-css.element-title',
                      description:
                        'public-knowledge.appearances.items.code-custom.custom-footer.footer-css.description',
                      emptyPlaceholder: '',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'footerCss',
                        'buildConfig.footer.css',
                      ),
                      accept: '.css',
                      fileName: 'mayday-footer.css',
                      component: 'FileInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                  ],
                },
                {
                  label: 'headerVisibility',
                  title:
                    'public-knowledge.appearances.items.code-custom.custom-header.element-title',
                  description:
                    'public-knowledge.appearances.items.code-custom.custom-header.description',
                  inputType: 'boolean',
                  path: 'buildConfig.customHeader.visibility',
                  emptyPlaceholder: 'false',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'headerVisibility',
                    'buildConfig.customHeader.visibility',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                  changingElements: [
                    {
                      label: 'headerHtml',
                      inputType: 'text',
                      path: 'buildConfig.customHeader.html',
                      title:
                        'public-knowledge.appearances.items.code-custom.custom-header.header-html.element-title',
                      description:
                        'public-knowledge.appearances.items.code-custom.custom-header.header-html.description',
                      emptyPlaceholder: '',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'headerHtml',
                        'buildConfig.customHeader.html',
                      ),
                      accept: '.html',
                      fileName: 'mayday-header.html',
                      component: 'FileInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                    {
                      label: 'headerCss',
                      inputType: 'text',
                      path: 'buildConfig.customHeader.css',
                      title:
                        'public-knowledge.appearances.items.code-custom.custom-header.header-css.element-title',
                      description:
                        'public-knowledge.appearances.items.code-custom.custom-header.header-css.description',
                      emptyPlaceholder: '',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'headerCss',
                        'buildConfig.customHeader.css',
                      ),
                      accept: '.css',
                      fileName: 'mayday-header.css',
                      component: 'FileInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                  ],
                },
                {
                  label: 'customCss',
                  title:
                    'public-knowledge.appearances.items.code-custom.custom-css.element-title',
                  description:
                    'public-knowledge.appearances.items.code-custom.custom-css.description',
                  inputType: 'boolean',
                  path: 'buildConfig.customCss.visibility',
                  emptyPlaceholder: 'false',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'customCss',
                    'buildConfig.customCss.visibility',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                  changingElements: [
                    {
                      label: 'customCssPath',
                      inputType: 'text',
                      path: 'buildConfig.customCss.path',
                      title:
                        'public-knowledge.appearances.items.code-custom.custom-css.file-title',
                      description:
                        'public-knowledge.appearances.items.code-custom.custom-css.file-description',
                      emptyPlaceholder: '',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'customCssPath',
                        'buildConfig.customCss.path',
                      ),
                      callout:
                        'public-knowledge.appearances.items.code-custom.custom-css.callout',
                      templateUrl: process.env.VUE_APP_CUSTOM_CSS_TEMPLATE,
                      accept: '.css',
                      fileName: 'mayday-custom.css',
                      component: 'FileInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                  ],
                },
                {
                  label: 'customJs',
                  title:
                    'public-knowledge.appearances.items.code-custom.custom-js.element-title',
                  description:
                    'public-knowledge.appearances.items.code-custom.custom-js.description',
                  inputType: 'boolean',
                  path: 'buildConfig.customJs.visibility',
                  emptyPlaceholder: 'false',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'customJs',
                    'buildConfig.customJs.visibility',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                  display: 'PUBLIC_CUSTOM_SCRIPT',
                  changingElements: [
                    {
                      label: 'customJsPath',
                      inputType: 'text',
                      path: 'buildConfig.customJs.path',
                      title:
                        'public-knowledge.appearances.items.code-custom.custom-js.file-title',
                      description:
                        'public-knowledge.appearances.items.code-custom.custom-js.file-description',
                      emptyPlaceholder: '',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'customJsPath',
                        'buildConfig.customJs.path',
                      ),
                      accept: '.js',
                      fileName: 'mayday-custom.js',
                      component: 'FileInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                  ],
                },
                {
                  label: 'gtmVisibility',
                  title:
                    'public-knowledge.appearances.items.code-custom.gtm.element-title',
                  description:
                    'public-knowledge.appearances.items.code-custom.gtm.description',
                  inputType: 'boolean',
                  path: 'buildConfig.gtm.visibility',
                  emptyPlaceholder: 'false',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'gtmVisibility',
                    'buildConfig.gtm.visibility',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                  changingElements: [
                    {
                      label: 'gtmId',
                      inputType: 'text',
                      path: 'buildConfig.gtm.id',
                      title:
                        'public-knowledge.appearances.items.code-custom.gtm.id.element-title',
                      description:
                        'public-knowledge.appearances.items.code-custom.gtm.id.description',
                      emptyPlaceholder: 'GTM-XXXXXXX',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'gtmId',
                        'buildConfig.gtm.id',
                      ),
                      component: 'TextInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                  ],
                },
              ],
            },
          ]
        : []),
    ],
  },

  configuration: {
    title: 'public-knowledge.configuration.title',
    name: 'configuration',
    subItem: [
      ...(!!focusCompanyPublicConfig.knowledgeId
        ? [
            {
              title: 'public-knowledge.configuration.items.language.title',
              icon: ['fal', 'globe-africa'],
              name: 'configuration',
              hash: '#language',
              changingElements: [
                {
                  label: 'language',
                  title:
                    'public-knowledge.configuration.items.language.language-title',
                  description:
                    'public-knowledge.configuration.items.language.language-description',
                  inputType: 'collapse',
                  component: 'CollapseInput',
                  isMonolingual: isMonolingual(
                    focusKnowledge.supportedLanguages,
                  ),
                  supportedLanguages: focusKnowledge.supportedLanguages,
                  items: getCollapsableItems(
                    focusKnowledge.publicData.translations,
                    focusKnowledge.supportedLanguages,
                    focusKnowledge.defaultLanguage,
                    operations,
                  ),
                },
              ],
            },
          ]
        : []),
      ...(!!focusCompanyPublicConfig.knowledgeId
        ? [
            {
              title: 'public-knowledge.configuration.items.domain.title',
              icon: ['fal', 'key'],
              name: 'configuration',
              hash: '#domain',
              changingElements: [
                {
                  label: 'hostname',
                  title:
                    'public-knowledge.configuration.items.domain.default-domain',
                  description:
                    'public-knowledge.configuration.items.domain.default-domain-description',
                  inputType: 'textCopy',
                  path: 'hostname',
                  emptyPlaceholder: 'https://faq.company-name',
                  append: 'mayday.cx',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'hostname',
                    'hostname',
                  ),
                  component: 'TextCopyInput',
                  width: '50%',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                },
                {
                  label: 'customHostname',
                  title:
                    'public-knowledge.configuration.items.domain.custom-domain',
                  description:
                    'public-knowledge.configuration.items.domain.custom-domain-description',
                  inputType: 'text',
                  path: 'customHostname',
                  emptyPlaceholder:
                    'public-knowledge.configuration.items.domain.custom-domain-empty-placeholder',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'customHostname',
                    'customHostname',
                  ),
                  component: 'TextInput',
                  width: '30%',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                  formatMethod(tag) {
                    const validHostnameChars =
                      /^([a-zA-Z0-9][a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.){0,2}([a-zA-Z0-9][a-zA-Z0-9-]{0,62}[a-zA-Z0-9])$/g;
                    return validHostnameChars.test(tag)
                      ? {
                          storeAction:
                            'publicKnowledgeModule/checkCdnEndpointValidity',
                          value: tag,
                        }
                      : false;
                  },
                  formatError: 'customHostnameError',
                },
              ],
            },
          ]
        : []),
      ...(!!focusCompanyPublicConfig.knowledgeId
        ? [
            {
              title: 'public-knowledge.configuration.items.search.title',
              icon: ['fal', 'search'],
              name: 'configuration',
              hash: '#search',
              changingElements: [
                {
                  label: 'displayStep',
                  title:
                    'public-knowledge.configuration.items.search.display-step-title',
                  description:
                    'public-knowledge.configuration.items.search.display-step-description',
                  activeMessage:
                    'public-knowledge.configuration.items.search.display-step-active-message',
                  disableMessage:
                    'public-knowledge.configuration.items.search.display-step-disable-message',
                  inputType: 'boolean',
                  path: 'preferences',
                  emptyPlaceholder: 'true',
                  value: getValueForHierarchy(
                    focusKnowledge,
                    operations,
                    'displayStep',
                    'preferences',
                    'name',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.HIERARCHY_PREFERENCES,
                },
                {
                  label: 'displayKeyStep',
                  title:
                    'public-knowledge.configuration.items.search.display-key-step-title',
                  description:
                    'public-knowledge.configuration.items.search.display-key-step-description',
                  activeMessage:
                    'public-knowledge.configuration.items.search.display-key-step-active-message',
                  disableMessage:
                    'public-knowledge.configuration.items.search.display-key-step-disable-message',
                  inputType: 'boolean',
                  path: 'preferences',
                  emptyPlaceholder: 'true',
                  value: getValueForHierarchy(
                    focusKnowledge,
                    operations,
                    'displayKeyStep',
                    'preferences',
                    'name',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.HIERARCHY_PREFERENCES,
                },
                // {
                //   label: 'useFilters',
                //   title:
                //     'public-knowledge.configuration.items.search.use-filters-title',
                //   description:
                //     'public-knowledge.configuration.items.search.use-filters-description',
                //   activeMessage:
                //     'public-knowledge.configuration.items.search.use-filters-active-message',
                //   disableMessage:
                //     'public-knowledge.configuration.items.search.use-filters-disable-message',
                //   inputType: 'boolean',
                //   path: 'preferences',
                //   emptyPlaceholder: 'true',
                //   value: getValueForHierarchy(
                //     focusKnowledge,
                //     operations,
                //     'useFilters',
                //     'preferences',
                //     'name',
                //   ),
                //   component: 'BooleanInput',
                //   direction: DIRECTIONS.HIERARCHY_PREFERENCES,
                // },
                {
                  label: 'displayContent',
                  title:
                    'public-knowledge.configuration.items.search.display-content-title',
                  description:
                    'public-knowledge.configuration.items.search.display-content-description',
                  activeMessage:
                    'public-knowledge.configuration.items.search.display-content-active-message',
                  disableMessage:
                    'public-knowledge.configuration.items.search.display-content-disable-message',
                  inputType: 'boolean',
                  path: 'preferences',
                  emptyPlaceholder: 'true',
                  value: getValueForHierarchy(
                    focusKnowledge,
                    operations,
                    'displayContent',
                    'preferences',
                    'name',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.HIERARCHY_PREFERENCES,
                },
              ],
            },
          ]
        : []),
      {
        title: 'public-knowledge.configuration.items.seo.title',
        icon: ['fal', 'globe'],
        name: 'configuration',
        hash: '#seo',
        changingElements: [
          ...(!!focusCompanyPublicConfig.knowledgeId
            ? [
                {
                  label: 'publicVisibility',
                  title:
                    'public-knowledge.configuration.items.seo.public-visibility-title',
                  description:
                    'public-knowledge.configuration.items.seo.public-visibility-description',
                  activeMessage:
                    'public-knowledge.configuration.items.seo.public-visibility-active-message',
                  disableMessage:
                    'public-knowledge.configuration.items.seo.public-visibility-disable-message',
                  inputType: 'boolean',
                  path: 'buildConfig.publicVisibility',
                  emptyPlaceholder: 'false',
                  value: getValue(
                    focusCompanyPublicConfig,
                    operations,
                    'publicVisibility',
                    'buildConfig.publicVisibility',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PUBLIC_CONFIG,
                  changingElements: [
                    {
                      label: 'generateSitemap',
                      title:
                        'public-knowledge.configuration.items.seo.generate-sitemap-title',
                      description:
                        'public-knowledge.configuration.items.seo.generate-sitemap-description',
                      activeMessage:
                        'public-knowledge.configuration.items.seo.generate-sitemap-active-message',
                      disableMessage:
                        'public-knowledge.configuration.items.seo.generate-sitemap-disable-message',
                      inputType: 'boolean',
                      path: 'buildConfig.generateSitemap',
                      emptyPlaceholder: 'false',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'generateSitemap',
                        'buildConfig.generateSitemap',
                      ),
                      component: 'BooleanInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                    },
                    {
                      label: 'metaTagGoogle',
                      title:
                        'public-knowledge.configuration.items.seo.meta-tag-google-title',
                      description:
                        'public-knowledge.configuration.items.seo.meta-tag-google-description',
                      inputType: 'text',
                      path: 'buildConfig.head.meta.name=google-site-verification.content',
                      emptyPlaceholder:
                        'public-knowledge.configuration.items.seo.meta-tag-google-empty-placeholder',
                      value: getValue(
                        focusCompanyPublicConfig,
                        operations,
                        'metaTagGoogle',
                        'buildConfig.head.meta.name=google-site-verification.content',
                      ),
                      width: '100%',
                      component: 'TextInput',
                      direction: DIRECTIONS.PUBLIC_CONFIG,
                      formatMethod(tag) {
                        const content = tag
                          .replace('<meta ', '')
                          .replace('/>', '')
                          .replaceAll('"', '')
                          .split(' ')
                          .map((el) => el.split('='));
                        if (content[0][1] !== 'google-site-verification')
                          return false;
                        return content[1][1];
                      },
                      renderMethod(content) {
                        return `<meta name="google-site-verification" content="${content}" />`;
                      },
                    },
                  ],
                },
              ]
            : []),
          {
            label: 'opengraph',
            title: 'public-knowledge.configuration.items.seo.opengraph.title',
            description:
              'public-knowledge.configuration.items.seo.opengraph.description',
            activeMessage:
              'public-knowledge.configuration.items.seo.opengraph.active-message',
            disableMessage:
              'public-knowledge.configuration.items.seo.opengraph.disable-message',
            inputType: 'boolean',
            path: 'buildConfig.head.opengraph',
            emptyPlaceholder: 'false',
            value: getValue(
              focusCompanyPublicConfig,
              operations,
              'opengraph',
              'buildConfig.head.opengraph',
            ),
            component: 'BooleanInput',
            direction: DIRECTIONS.PUBLIC_CONFIG,
            changingElements: [
              {
                label: 'opengraphImage',
                title:
                  'public-knowledge.configuration.items.seo.opengraph.image.title',
                description:
                  'public-knowledge.configuration.items.seo.opengraph.image.description',
                inputType: 'img',
                path: 'buildConfig.head.meta.hid=og:image.content',
                emptyPlaceholder: require('assets/upload-placeholder.svg'),
                emptyPlaceholderHover: require('assets/upload-placeholder.gif'),
                deleteWrapperAlign: 'align-items-end',
                value: getValue(
                  focusCompanyPublicConfig,
                  operations,
                  'opengraphImage',
                  'buildConfig.head.meta.hid=og:image.content',
                ),
                size: { width: '600', height: '315' },
                component: 'ImageInput',
                direction: DIRECTIONS.PUBLIC_CONFIG,
                style: {
                  padding: '0px',
                  height: '110px',
                  width: '230px',
                  'max-width': '230px !important',
                },
                emptyStyle: {
                  padding: '8px',
                },
                imgEmptyStyle: {
                  'max-width': '50%',
                  'max-height': '50%',
                },
                modalTitle:
                  'public-knowledge.configuration.items.seo.opengraph.image.modal-title',
              },
            ],
          },
        ],
      },
    ],
  },
  ...(!!focusCompanyPublicConfig.knowledgeId
    ? {
        marketplace: {
          title: 'public-knowledge.marketplace.title',
          name: 'marketplace',
          subItem: [
            {
              title: 'public-knowledge.marketplace.items.plugins.title',
              icon: ['fal', 'dumbbell'],
              name: 'marketplace',
              hash: '#catalogue',
              changingElements: [
                {
                  label: 'relatedContents',
                  title:
                    'public-knowledge.marketplace.items.plugins.related-contents-title',
                  description:
                    'public-knowledge.marketplace.items.plugins.related-contents-description',
                  activeMessage:
                    'public-knowledge.marketplace.items.plugins.general-active-message',
                  disableMessage:
                    'public-knowledge.marketplace.items.plugins.general-disable-message',
                  inputType: 'boolean',
                  path: 'RELATED_CONTENTS.isVisible',
                  emptyPlaceholder: 'false',
                  value: getValueForPlugins(
                    focusKnowledge,
                    operations,
                    'relatedContents',
                    'RELATED_CONTENTS.isVisible',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PLUGINS,
                },
                {
                  label: 'pinnedContents',
                  title:
                    'public-knowledge.marketplace.items.plugins.pinned-contents-title',
                  description:
                    'public-knowledge.marketplace.items.plugins.pinned-contents-description',
                  activeMessage:
                    'public-knowledge.marketplace.items.plugins.general-active-message',
                  disableMessage:
                    'public-knowledge.marketplace.items.plugins.general-disable-message',
                  inputType: 'boolean',
                  path: 'PINNED_CONTENTS.isVisible',
                  emptyPlaceholder: 'false',
                  value: getValueForPlugins(
                    focusKnowledge,
                    operations,
                    'pinnedContents',
                    'PINNED_CONTENTS.isVisible',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PLUGINS,
                },
                {
                  label: 'askInSearch',
                  title:
                    'public-knowledge.marketplace.items.plugins.ask-in-search-title',
                  description:
                    'public-knowledge.marketplace.items.plugins.ask-in-search-description',
                  activeMessage:
                    'public-knowledge.marketplace.items.plugins.general-active-message',
                  disableMessage:
                    'public-knowledge.marketplace.items.plugins.general-disable-message',
                  inputType: 'boolean',
                  path: 'ASK_IN_SEARCH.isVisible',
                  emptyPlaceholder: 'false',
                  value: getValueForPlugins(
                    focusKnowledge,
                    operations,
                    'askInSearch',
                    'ASK_IN_SEARCH.isVisible',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PLUGINS,
                  featureFlag: 'BRAIN_ENABLE_ASK_SELFCARE',
                },
                {
                  label: 'satisfaction',
                  title:
                    'public-knowledge.marketplace.items.plugins.satisfaction-title',
                  description:
                    'public-knowledge.marketplace.items.plugins.satisfaction-description',
                  activeMessage:
                    'public-knowledge.marketplace.items.plugins.general-active-message',
                  disableMessage:
                    'public-knowledge.marketplace.items.plugins.general-disable-message',
                  inputType: 'boolean',
                  path: 'SATISFACTION.isVisible',
                  emptyPlaceholder: 'false',
                  value: getValueForPlugins(
                    focusKnowledge,
                    operations,
                    'satisfaction',
                    'SATISFACTION.isVisible',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PLUGINS,
                },
                {
                  label: 'images',
                  title:
                    'public-knowledge.marketplace.items.plugins.images-title',
                  description:
                    'public-knowledge.marketplace.items.plugins.images-description',
                  activeMessage:
                    'public-knowledge.marketplace.items.plugins.images-active-message',
                  activeIcon: ['fad', 'hourglass'],
                  activeColorIsYellow: true,
                  disableMessage:
                    'public-knowledge.marketplace.items.plugins.general-disable-message',
                  inputType: 'boolean',
                  path: 'IMAGES.isVisible',
                  emptyPlaceholder: 'false',
                  value: getValueForPlugins(
                    focusKnowledge,
                    operations,
                    'images',
                    'IMAGES.isVisible',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PLUGINS,
                },
                {
                  label: 'contactForm',
                  title:
                    'public-knowledge.marketplace.items.plugins.contact-form-title',
                  description:
                    'public-knowledge.marketplace.items.plugins.contact-form-description',
                  activeMessage:
                    'public-knowledge.marketplace.items.plugins.general-active-message',
                  disableMessage:
                    'public-knowledge.marketplace.items.plugins.general-disable-message',
                  inputType: 'boolean',
                  path: 'CONTACT_FORM.isVisible',
                  emptyPlaceholder: 'false',
                  value: getValueForPlugins(
                    focusKnowledge,
                    operations,
                    'contactForm',
                    'CONTACT_FORM.isVisible',
                  ),
                  component: 'BooleanInput',
                  direction: DIRECTIONS.PLUGINS,
                  changingElements: [
                    {
                      label: 'contactFormHref',
                      inputType: 'text',
                      path: 'CONTACT_FORM.elements.1.props.href',
                      title:
                        'public-knowledge.marketplace.items.plugins.contact-form-href-title',
                      description:
                        'public-knowledge.marketplace.items.plugins.contact-form-href-description',
                      emptyPlaceholder:
                        'public-knowledge.marketplace.items.plugins.contact-form-href-empty-placeholder',
                      value: getValueForPlugins(
                        focusKnowledge,
                        operations,
                        'contactFormHref',
                        'CONTACT_FORM.elements.1.props.href',
                      ),
                      formatMethod(url) {
                        try {
                          new URL(url);
                          return url;
                        } catch (e) {
                          return false;
                        }
                      },
                      width: '50%',
                      component: 'TextInput',
                      direction: DIRECTIONS.PLUGINS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      }
    : {}),
});
