<template>
  <modal class="create-notification-modal" :show="localOpen">
    <div class="row d-flex mt-5 justify-content-center">
      <div class="col-11 text-center">
        <modal-header
          :style-name="{ backgroundColor: '#0081F91A' }"
          class="mb-3"
        >
          <div slot="icon" class="d-inline-flex">
            <font-awesome-icon
              class="icon blue-mayday"
              :icon="['fas', 'bell']"
            />
          </div>
          <div class="mt-3">
            <div>
              <p class="mb-0 font-weight-bold">
                {{ $t('knowledge.modals.notifications.main-title') }}
              </p>
            </div>
          </div>
        </modal-header>
        <WarningCallout class="mb-3" warning="notifications" />
        <div class="flex-column d-flex justify-content-center subcontent p-2">
          <!-- NOTIFICATION EDITION -->
          <NotificationEdition
            ref="notificationEdition"
            class="notification-edition-options"
            v-show="currentStep === 0"
            :node-id="entityId"
            :link-content="linkContent"
            @change="notification = $event"
          />

          <!-- Body step 2 -->
          <NotificationReview
            class="notification-edition-options"
            v-if="currentStep === 1"
            :notification="notification"
          />
        </div>
        <!-- Body stepper -->
        <div class="section d-flex justify-content-center mt-4">
          <span
            v-for="(step, index) in steps"
            :key="index"
            :class="[
              'notification-step-item',
              step === currentStep ? 'notification-step-item-selected' : '',
            ]"
          ></span>
        </div>
      </div>
    </div>
    <template slot="footer">
      <!-- Body actions -->
      <div class="d-flex justify-content-end">
        <base-button
          type="secondary"
          outline
          v-if="currentStep === 0"
          @click="localOpen = false"
          size="sm"
          >{{ $t('knowledge.modals.notifications.cancel') }}</base-button
        >
        <base-button
          outline
          v-else
          type="secondary"
          @click="currentStep -= 1"
          size="sm"
          >{{ $t('knowledge.modals.notifications.previous') }}</base-button
        >
        <base-button
          class="ml-2"
          v-if="currentStep === 0"
          @click="currentStep += 1"
          type="primary"
          size="sm"
          :disabled="isFormValid"
          >{{ $t('knowledge.modals.notifications.next') }}</base-button
        >
        <base-button
          v-else
          @click="createNotification"
          type="primary"
          size="sm"
          >{{ $t('knowledge.modals.notifications.send') }}</base-button
        >
      </div>
    </template>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import NotificationEdition from './NotificationEdition';
import NotificationReview from './NotificationReview';
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';
import WarningCallout from '@/views/KnowledgeEditor/ParametricContentBuilder/ParametricBuilderSibeBar/WarningCallout.vue';

import { mapActions } from 'vuex';

export default {
  name: 'CreateNotificationModal',
  components: {
    Modal,
    ModalHeader,
    NotificationEdition,
    NotificationReview,
    WarningCallout,
  },
  props: {
    open: Boolean,
    entityId: {
      type: String,
      default: null,
    },
    entityType: {
      type: String,
      default: null,
    },
    linkContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localOpen: this.open,
      steps: [0, 1],
      currentStep: 0,
      notification: {
        label: '',
        body: '',
        urgent: false,
        tags: [],
        targetGroupIds: [],
        link: false,
        attachments: [],
      },
    };
  },
  validations: {
    notification: {
      label: {
        required,
      },
      body: {
        required,
      },
      targetGroupIds: {
        required,
        minLength: minLength(1),
      },
    },
  },
  computed: {
    isFormValid() {
      const notificationEdition = this.$refs.notificationEdition;
      const hasDatesError = notificationEdition
        ? notificationEdition.hasDatesError
        : false;
      return (
        this.$v.notification.label.$invalid ||
        this.$v.notification.body.$invalid ||
        this.$v.notification.targetGroupIds.$invalid ||
        !this.attachmentsSendable ||
        hasDatesError
      );
    },
    attachmentsSendable() {
      let sendable = true;
      if (!this.notification.attachments) return sendable;
      if (this.notification.attachments.length) {
        sendable = this.notification.attachments
          .map((at) => !at.error || !at.loading)
          .every((el) => el);
      }
      return sendable;
    },
  },
  methods: {
    createNotification() {
      if (this.entityId) {
        this.notification.entityId = this.entityId;
        this.notification.entityType = this.entityType;
        this.notification.lang = this.$route.params.lang;
      }
      this.$emit('events', {
        eventName: 'choice',
        eventData: {
          notification: this.notification,
        },
      });
      this.localOpen = false;
    },
    ...mapActions('adminModule', ['getCompanyGroups']),
  },
  async created() {
    await this.getCompanyGroups();
  },
  watch: {
    localOpen() {
      if (!this.localOpen) {
        this.$emit('events', {
          eventName: 'close',
          eventData: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  width: 100%;
  border-bottom: 1px solid $grey-4-mayday;
}
.section {
  margin-bottom: 1rem;
}
.close-icon-notification {
  cursor: pointer;
  color: $grey-4-mayday;
  &:hover {
    color: $grey-5-mayday;
  }
}
.notification-step-item {
  height: 10px;
  width: 10px;
  background-color: $grey-5-mayday;
  border-radius: 50%;
  &:not(:last-child) {
    margin-right: 5px;
  }
}
.notification-step-item-selected {
  background-color: $blue-mayday;
}

.create-notification-modal {
  :deep() .modal-body {
    max-height: 80vh;
    overflow: auto;
  }
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
</style>
