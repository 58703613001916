import initState from './state';
import getters from './getters';
import actions from './actions';
import navigatorActions from './navigatorActions';
import maydayEditorActions from './maydayEditorActions';
import mutations from './mutations';
import diagnosticActions from './diagnosticActions';
import translationActions from './translationActions';

export default {
  namespaced: true,
  state: initState(),
  getters,
  actions: {
    ...actions,
    ...navigatorActions,
    ...maydayEditorActions,
    ...diagnosticActions,
    ...translationActions,
  },
  mutations,
};
