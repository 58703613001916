import * as types from './mutation-types';
import initState from './state';

export default {
  /**
   * GENERAL
   */
  [types.LOGOUT](state) {
    Object.assign(state, initState());
  },

  /**
   * KNOWLEDGES SETTINGS
   */

  [types.SET_ADMIN_KNOWLEDGES](state, payload) {
    state.adminKnowledges = payload;
  },

  [types.SET_FOCUS_ADMIN_KNOWLEDGE_ID](state, payload) {
    state.focusAdminKnowledgeId = payload;
  },

  [types.ADD_LANGUAGE](state, payload) {
    const idx = state.adminKnowledges.findIndex(
      (knowledge) => knowledge.id === payload.id,
    );

    if (idx === -1) return;

    payload.label = state.adminKnowledges[idx].label; // Keep default label

    state.adminKnowledges.splice(idx, 1, payload);
  },

  /**
   * COMPANY
   */

  [types.SET_COMPANY_HOSTNAMES](state, payload) {
    if (!payload) return;
    state.companyHostnames = payload.reduce((acc, companyPublicConfig) => {
      const key =
        companyPublicConfig.knowledgeId &&
        companyPublicConfig.knowledgeId.length
          ? companyPublicConfig.knowledgeId
          : 'default';
      acc[key] = companyPublicConfig.hostname;
      return acc;
    }, {});
  },

  // EMPLOYEES

  [types.SET_EMPLOYEES](state, payload) {
    state.employees = payload.results;
    state.employeesCount = payload.total;
  },
  [types.SET_EMPLOYEES_TABLE_LOADING](state, payload) {
    state.employeesTableIsLoading = payload;
  },
  [types.SET_EMPLOYEES_TABLE_FILTERS](state, payload) {
    state.employeesTableFilters = payload;
  },

  //EMPLOYEES MODAL

  [types.SET_MODAL_EMPLOYEES](state, payload) {
    state.modalEmployees = payload.results;
    state.modalEmployeesCount = payload.total;
  },
  [types.SET_MODAL_EMPLOYEES_TABLE_LOADING](state, payload) {
    state.modalEmployeesTableIsLoading = payload;
  },
  [types.SET_MODAL_EMPLOYEES_TABLE_FILTERS](state, payload) {
    state.modalEmployeesTableFilters = payload;
  },
  [types.SET_SHOW_MODAL_EMPLOYEES](state, payload) {
    state.showModalEmployees = payload;
  },

  // PENDING EMPLOYEES

  [types.SET_PENDING_EMPLOYEES](state, payload) {
    state.pendingEmployees = payload.results;
    state.pendingEmployeesCount = payload.total;
  },
  [types.SET_PENDING_EMPLOYEES_TABLE_LOADING](state, payload) {
    state.pendingEmployeesTableIsLoading = payload;
  },
  [types.SET_PENDING_EMPLOYEES_TABLE_FILTERS](state, payload) {
    state.pendingEmployeesTableFilters = {
      ...state.pendingEmployeesTableFilters,
      ...payload,
    };
  },

  // PENDING EMPLOYEES MODAL

  [types.SET_MODAL_PENDING_EMPLOYEES](state, payload) {
    state.modalPendingEmployees = payload.results;
    state.modalPendingEmployeesCount = payload.total;
  },
  [types.SET_MODAL_PENDING_EMPLOYEES_TABLE_LOADING](state, payload) {
    state.modalPendingEmployeesTableIsLoading = payload;
  },
  [types.SET_MODAL_PENDING_EMPLOYEES_TABLE_FILTERS](state, payload) {
    state.modalPendingEmployeesTableFilters = payload;
  },
  [types.SET_SHOW_MODAL_PENDING_EMPLOYEES](state, payload) {
    state.showModalPendingEmployees = payload;
  },

  [types.SET_GROUPS](state, payload) {
    state.groups = payload.groups;
  },
  [types.SET_GROUPS_LOADING](state, payload) {
    state.groupsIsLoading = payload;
  },
  [types.SET_ROLES](state, payload) {
    state.roles = payload.roles;
  },
  [types.SET_ROLES_LOADING](state, payload) {
    state.rolesIsLoading = payload;
  },
  [types.SET_REVIEWERS](state, payload) {
    state.reviewers = payload;
  },

  [types.SET_COMPANY_USER_LABEL_CATEGORIES](state, categories) {
    state.userLabelCategories = categories;
    state.categorySettingsLookup = categories.reduce((acc, category) => {
      const { id, icon, color } = category;
      acc[id] = { icon, color };
      return acc;
    }, {});
  },
  [types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING](state, payload) {
    state.userLabelCategoriesIsLoading = payload;
  },

  [types.UPDATE_ADMIN_NOTIFICATIONS](state, payload) {
    state.adminNotifications = payload;
  },

  //USERS ADMINISTRATION

  [types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING](state, payload) {
    state.usersForAdministrationTableIsLoading = payload;
  },

  [types.SET_USERS_FOR_ADMINISTRATION_TABLE](state, payload) {
    state.usersForAdministration = [...payload.results];
    if (payload.total) state.usersForAdministrationCount = payload.total;
  },

  [types.ADD_TO_USERS_ADMINISTRATION_FILTER](state, filtersCopy) {
    state.usersForAdministrationTableQueryParameter.currentPage = 1;
    state.usersForAdministrationTableQueryParameter.filters = filtersCopy;
  },

  [types.ADD_SEARCH_TO_FILTERS](state, { search }) {
    state.usersForAdministrationTableQueryParameter.currentPage = 1;
    state.usersForAdministrationTableQueryParameter.search = search;
  },

  [types.EMPTY_FILTERS_SEARCH](state) {
    state.usersForAdministrationTableQueryParameter = {
      currentPage: 1,
      totalPageCount:
        state.usersForAdministrationTableQueryParameter.totalPageCount,
      filters: [],
      sort: {},
      search: '',
    };
  },

  [types.SET_CURRENT_PAGE](state, { page }) {
    state.usersForAdministrationTableQueryParameter.currentPage = page;
  },
  [types.SET_PAGE_SIZE](state, { pageSize }) {
    state.usersForAdministrationTableQueryParameter.totalPageCount = pageSize;
    state.usersForAdministrationTableQueryParameter.currentPage = 1;
  },
  [types.EXPORT_USER_LOADING](state, { isPending, loading }) {
    state.userExportLoading[isPending ? 'pending' : 'notPending'] = loading;
  },

  [types.SET_USER_INVITATIONS](state, payload) {
    state.userInvitations = payload;
  },

  [types.SET_CONTRIBUTORS](state, payload) {
    state.contributors = payload;
  },

  // COMPANY SYNONYMS

  [types.SET_COMPANY_SYNONYMS](state, payload) {
    state.companySynonyms = payload;
  },

  // WEBSOCKETS
  [types.SET_WS_CONNECTION_ID](state, payload) {
    state.wsConnectionId = payload;
  }
};
