<template>
  <div>
    <div class="d-flex justify-content-center section">
      <h4 class="section-title">
        {{ $t('knowledge.modals.notifications.review-tile') }}
      </h4>
    </div>

    <div class="d-flex justify-content-center text-center section">
      <p>{{ $t('knowledge.modals.notifications.review-description') }}</p>
    </div>

    <div class="section-group">
      <div class="section">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.title.label') }}
        </h4>
        <h3>{{ notification.label }}</h3>
      </div>

      <div class="section">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.message.label') }}
        </h4>

        <MaydayNotificationEditor
          class="notification__reviewer"
          :message="notification.body"
          :editable="false"
        />
      </div>
      <div class="section" v-if="notification.attachments.length">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.attachment') }}
        </h4>
        <AttachmentList
          class="mt-1"
          :attachments="notification.attachments"
          :isInput="false"
          :displayHeader="false"
          :attachmentWidth="'49%'"
        ></AttachmentList>
      </div>

      <div class="section">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.groups.label') }}
        </h4>
        <div class="row ml-1" v-if="selectedGroups.length > 0">
          <span
            class="text-grey mr-4"
            v-for="group in selectedGroups"
            :key="group.id"
          >
            <font-awesome-icon :icon="['fad', 'users']"></font-awesome-icon>
            {{ group.name }}
          </span>
        </div>
        <h5 v-else>{{ $t('knowledge.modals.notifications.groups.empty') }}</h5>
      </div>

      <div class="section">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.tags.label') }}
        </h4>
        <div v-if="notification.tags.length > 0" class="tags-container">
          <el-tag
            v-for="(tag, index) in notification.tags"
            :key="index"
            class="mt-2 mr-2"
            >{{ tag }}</el-tag
          >
        </div>
        <h5 v-else>{{ $t('knowledge.modals.notifications.tags.empty') }}</h5>
      </div>

      <div class="section">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.urgent.label') }}
        </h4>
        <h5>
          {{
            notification.urgent
              ? $t('knowledge.modals.notifications.urgent.is')
              : $t('knowledge.modals.notifications.urgent.is_not')
          }}
        </h5>
      </div>
      <div class="section" v-if="notification.publicationDateStart">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.publication-date-start') }}
          <el-tooltip
            class="ml-1"
            :content="$t('knowledge.modals.notifications.timezone')"
            placement="right"
          >
            <font-awesome-icon :icon="['fal', 'info-circle']" />
          </el-tooltip>
        </h4>
        <h5>
          {{
            $t('knowledge.modals.notifications.date', {
              date: formatTimestamp(notification.publicationDateStart).date,
              hour: formatTimestamp(notification.publicationDateStart).hour,
            })
          }}
        </h5>
      </div>
      <div class="section" v-if="notification.publicationDateEnd">
        <h4 class="section-title">
          {{ $t('knowledge.modals.notifications.publication-date-end') }}
          <el-tooltip
            class="ml-1"
            :content="$t('knowledge.modals.notifications.timezone')"
            placement="right"
          >
            <font-awesome-icon :icon="['fal', 'info-circle']" />
          </el-tooltip>
        </h4>
        <h5>
          {{
            $t('knowledge.modals.notifications.date', {
              date: formatTimestamp(notification.publicationDateEnd).date,
              hour: formatTimestamp(notification.publicationDateEnd).hour,
            })
          }}
        </h5>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MaydayNotificationEditor from '@/components/Editors/MaydayNotificationEditor';
import AttachmentList from '@/components/Attachments/AttachmentList';
import formatTimestamp from '@/utils/formatTimestamp';

export default {
  name: 'NotificationReview',
  components: { MaydayNotificationEditor, AttachmentList },
  props: {
    notification: {
      type: Object,
      default: () => ({
        title: '',
        message: '',
        urgent: false,
        tags: [],
        targetGroupIds: [],
        link: false,
      }),
    },
  },
  data() {
    return {
      formatTimestamp,
    };
  },
  computed: {
    selectedGroups() {
      return this.groups.filter((g) =>
        this.notification.targetGroupIds.includes(g.id),
      );
    },
    ...mapGetters('adminModule', ['groups']),
  },
};
</script>
<style lang="scss" scoped>
.section {
  text-align: left;
  margin-bottom: 1rem;
  .section-title {
    color: $grey-7-mayday;
    text-transform: uppercase;
  }
}
.section-group {
  border: 1px solid $grey-4-mayday;
  border-radius: 5px;
  padding: 0.8rem;
}
.section-message {
  font-size: 12px;
}
.tags-container {
  max-height: 100px;
  overflow: auto;
}

.notification__reviewer {
  border: 1px solid $grey-4-mayday;
  border-radius: 4px;
  min-height: 20vh;
  background-color: $grey-1-mayday;

  :deep() p {
    font-size: 0.9rem !important;
    margin: 4px 0 !important;
  }

  :deep() li {
    margin-bottom: 6px !important;
  }
}
</style>
