export class BrainService {
  constructor(data) {
    this.data = data;
  }

  getParameters() {
    return this.data.getParameters();
  }
  validateParameters(options) {
    return this.data.validateParameters(options);
  }

  async *fetchSummary(accessToken, body, summaryType) {
    for await (const chunk of this.data.fetchSummary(
      accessToken,
      body,
      summaryType,
    )) {
      yield chunk;
    }
  }

  async *fetchActionItems(accessToken, body, abortController) {
    for await (const chunk of this.data.fetchActionItems(
      accessToken,
      body,
      abortController,
    )) {
      yield chunk;
    }
  }

  async *askStream(
    accessToken,
    queryId,
    queryBody,
    dev = false,
    askInterface = 'beta',
  ) {
    for await (const chunk of this.data.askStream(
      accessToken,
      queryId,
      queryBody,
      dev,
      askInterface,
    )) {
      yield chunk;
    }
  }

  async *fetchTranslation(accessToken, text, language, abortController) {
    for await (const chunk of this.data.fetchTranslation(
      accessToken,
      text,
      language,
      abortController,
    )) {
      yield chunk;
    }
  }

  async *fetchCorrection(accessToken, text, correctionType, abortController) {
    for await (const chunk of this.data.fetchCorrection(
      accessToken,
      text,
      correctionType,
      abortController,
    )) {
      yield chunk;
    }
  }

  sendAskFeedback(accessToken, queryId, queryBody, feedback) {
    return this.data.sendAskFeedback(accessToken, queryId, queryBody, feedback);
  }

  sendAskBetaFeedback(accessToken, queryId, queryBody, feedback) {
    return this.data.sendAskBetaFeedback(
      accessToken,
      queryId,
      queryBody,
      feedback,
    );
  }

  async postArticleToGuide(
    objective,
    label,
    body,
    language,
    knowledge,
    knowledgeId,
    caseParameters,
  ) {
    return this.data.postArticleToGuide(
      objective,
      label,
      body,
      language,
      knowledge,
      knowledgeId,
      caseParameters,
    );
  }

  async analyzeCustomizationParameters(accessToken, queryId, textSpec) {
    return this.data.analyzeCustomizationParameters(
      accessToken,
      queryId,
      textSpec,
    );
  }

  async getCustomization(accessToken, queryId) {
    return this.data.getCustomization(accessToken, queryId);
  }

  async setCustomization(accessToken, queryId, customization) {
    return this.data.setCustomization(accessToken, queryId, customization);
  }

  async delCustomization(accessToken, queryId) {
    return this.data.delCustomization(accessToken, queryId);
  }

  async getArticleToGuideStatus(processId) {
    return this.data.getArticleToGuideStatus(processId);
  }

  async cancelArticleToGuide(processId) {
    return this.data.cancelArticleToGuide(processId);
  }

  async getArticleToGuideSuggestion(label, body, language) {
    return this.data.getArticleToGuideSuggestion(label, body, language);
  }
  async postTryOutQuestions({ lang, n }) {
    return this.data.postTryOutQuestions({ lang, n });
  }

  // concepts
  async rejectConcept({ conceptId, documentId }) {
    return this.data.rejectConcept({ documentId, conceptId });
  }
  async acceptConcept({ conceptId, documentId }) {
    return this.data.acceptConcept({ documentId, conceptId });
  }
  async searchConcepts(payload) {
    return this.data.searchConcepts(payload);
  }
  async deleteConcept(conceptId) {
    return this.data.deleteConcept(conceptId);
  }
  async createConcept(concept) {
    const { data } = await this.data.createConcept(concept);
    if (!data) return {};
    const { contents, sessionId } = data;
    return { contents, sessionId };
  }
  async sendSuggestedDocs({ sessionId, answers }) {
    const { data } = await this.data.sendSuggestedDocs({ sessionId, answers });
    if (!data) return {};
    const { done, contents } = data;
    return { done, contents };
  }

  // Translations
  async triggerContentTranslation({
    documentId,
    sourceLanguage,
    targetLanguage,
  }) {
    const { data } = await this.data.triggerContentTranslation({
      documentId,
      sourceLanguage,
      targetLanguage,
    });
    if (!data) return {};
    return data.statusId;
  }
  async getTranslationStatus(lockId) {
    const { data } = await this.data.getTranslationStatus(lockId);
    if (!data) return {};
    return data.status;
  }
}
