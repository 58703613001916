export class SettingService {
  constructor(data) {
    this.data = data;
  }

  async getCompanyIpRanges() {
    return this.data.getCompanyIpRanges();
  }

  async setCompanyIpRanges(ipRanges) {
    return this.data.setCompanyIpRanges(ipRanges);
  }

  async deleteCompanyIpRanges() {
    return this.data.deleteCompanyIpRanges();
  }
}
