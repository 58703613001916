import data from './content.data.raw.js';
import bulkData from './content.bulk.data.raw.js';

export class ParametricContentServiceData {
  constructor(client, axiosClient) {
    this.client = client;
    this.axiosClient = axiosClient;
  }

  /**
   * CREATE METHODS
   */

  async createContent(
    knowledge,
    knowledgeId,
    type,
    label,
    parameters,
    caseParameters,
    accessRestrictions,
    labels,
    templateId,
    lang,
  ) {
    return this.client
      .mutate({
        mutation: data.createContent,
        variables: {
          knowledge,
          knowledgeId,
          type,
          label,
          parameters,
          caseParameters,
          accessRestrictions,
          labels,
          templateId,
          lang,
        },
      })
      .then((response) => response.data.createdContent)
      .catch((error) => error);
  }

  async addChild(parentId, type, label, lang) {
    return this.client
      .mutate({
        mutation: data.addChild,
        variables: {
          parentId,
          type,
          label,
          lang,
        },
      })
      .then((response) => response.data.addChild)
      .catch((error) => error);
  }

  async createContribution(knowledge, label, body, parameters, attachments) {
    return this.client
      .mutate({
        mutation: data.createContribution,
        variables: {
          knowledge,
          label,
          body,
          parameters,
          attachments,
        },
      })
      .then((response) => response.data.createContribution)
      .catch((error) => error);
  }

  async attachFiles(files) {
    this.axiosClient.defaults.headers.common['Content-Type'] =
      'multipart/form-data';
    return this.axiosClient.post('/contents/attach-files', files);
  }

  async importFiles(files) {
    this.axiosClient.defaults.headers.common['Content-Type'] =
      'multipart/form-data';
    return this.axiosClient.post('/contents/import-files', files);
  }

  async addSatisfactionMark(contentId, thumbType, lang) {
    return this.client
      .mutate({
        mutation: data.addSatisfactionMark,
        variables: {
          contentId,
          thumbType,
          lang,
        },
      })
      .then((response) => response.data.satisfactionMark)
      .catch((error) => error);
  }

  async setContentRedirection(contentId, entityId, entityType, lang) {
    return this.client
      .mutate({
        mutation: data.setContentRedirection,
        variables: {
          contentId,
          entityId,
          entityType,
          lang,
        },
      })
      .then((response) => response.data.contentRedirection)
      .catch((error) => error);
  }

  /**
   * READ QUERIES
   */

  async getContentById(id, lang) {
    return this.client
      .query({
        query: data.getContentById,
        variables: { id, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentById)
      .catch((error) => error);
  }
  async getContentChildrenById(id, lang) {
    return this.client
      .query({
        query: data.getContentChildrenById,
        variables: { id, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentById)
      .catch((error) => error);
  }

  async getLightContentById(id, lang) {
    return this.client
      .query({
        query: data.getLightContentById,
        variables: { id, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentById)
      .catch((error) => error);
  }

  async getLoadingLanguages(documentId) {
    return this.client
      .query({
        query: data.getLoadingLanguages,
        variables: { id: documentId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getLoadingLanguages)
      .catch((error) => error);
  }
  async getContentConceptsById(id, lang) {
    return this.client
      .query({
        query: data.getContentConceptsById,
        variables: { id, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentById)
      .catch((error) => error);
  }

  async getBacklinksById(contentId) {
    return this.client
      .query({
        query: data.getBacklinksById,
        variables: { contentId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentBacklinks)
      .catch((error) => error);
  }

  async getContentByIdWithAncestors(id, lang, rootId = null) {
    return this.client
      .query({
        query: data.getContentByIdWithAncestors,
        variables: { id, lang, rootId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getContentByIdWithAncestors)
      .catch((error) => error);
  }
  async getWithEnriched(id, skipAncestors) {
    return this.client
      .query({
        query: data.getWithEnriched,
        variables: { id, skipAncestors },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getWithEnriched)
      .catch((error) => error);
  }

  async getAttachedContentsByProductId(productId) {
    return this.client
      .query({
        query: data.getAttachedContentsByProductId,
        variables: { productId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.contents)
      .catch((error) => error);
  }

  async getContentsByProductId(productId, count, search) {
    return this.client
      .query({
        query: data.getContentsByProductId,
        variables: { productId, count, search },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.contents)
      .catch((error) => error);
  }

  async getContentsByPartialProduct(parameters, count, search) {
    return this.client
      .query({
        query: data.getContentsByPartialProduct,
        variables: { parameters, count, search },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.contents)
      .catch((error) => error);
  }

  async getAttachedContentsByPartialProduct(parameters, count) {
    return this.client
      .query({
        query: data.getAttachedContentsByPartialProduct,
        variables: { parameters, count },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.contents)
      .catch((error) => error);
  }

  async filterContentsQuery(
    searchFilter,
    knowledgeFilters,
    productFilters,
    caseFilters,
    statusFilters,
    accessRestrictionsFilters,
    labelsFilters,
    contributionFilter,
    cached,
    pageSize,
    page,
  ) {
    return this.client
      .query({
        query: data.filterContentsQuery,
        variables: {
          searchFilter,
          knowledgeFilters,
          productFilters,
          caseFilters,
          statusFilters,
          accessRestrictionsFilters,
          labelsFilters,
          contributionFilter,
          pageSize,
          page,
        },
        fetchPolicy: cached ? 'cache-first' : 'no-cache',
      })
      .then((response) => response.data.filterContents)
      .catch((error) => error);
  }

  async filterContentsCount(
    knowledgeFilters,
    productFilters,
    caseFilters,
    statusFilters,
    accessRestrictionsFilters,
    labelsFilters,
    contributionFilter,
    knowledgeId,
  ) {
    return this.client
      .query({
        query: data.filterContentsCount,
        variables: {
          knowledgeFilters,
          productFilters,
          caseFilters,
          statusFilters,
          accessRestrictionsFilters,
          labelsFilters,
          contributionFilter,
          knowledgeId,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.filterContents.total)
      .catch((error) => error);
  }

  async retrieveContentDescendents(contentId, lang) {
    return this.client
      .query({
        query: data.retrieveContentDescendents,
        variables: { contentId, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.retrieveContentDescendents)
      .catch((error) => error);
  }

  async getArchiveById(archiveId, lang) {
    return this.client
      .query({
        query: data.getArchiveById,
        variables: { archiveId, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getArchiveById)
      .catch((error) => error);
  }

  async countRelatedContentReferences(contentId, knowledgeId) {
    return this.client
      .query({
        query: data.countRelatedContentReferences,
        variables: { contentId, knowledgeId },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.countRelatedContentReferences)
      .catch((error) => error);
  }

  async getArchives(knowledge, pageSize = 20, page = 1, search = '') {
    return this.client
      .query({
        query: data.getArchives,
        variables: { knowledge, pageSize, page, search },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getArchives)
      .catch((error) => error);
  }

  async updateContent(variables) {
    return this.client
      .mutate({
        mutation: data.updateContent,
        variables,
      })
      .then((response) => response.data.updateContent)
      .catch((error) => error);
  }

  async updateContentTranslation(variables) {
    return this.client
      .mutate({
        mutation: data.updateContentTranslation,
        variables,
      })
      .then((response) => response.data.updateContentTranslation)
      .catch((error) => error);
  }

  async toggleKeyStep(id, type) {
    return this.client
      .mutate({
        mutation: data.toggleKeyStep,
        variables: { id, type },
      })
      .then((response) => response.data.toggleKeyStep)
      .catch((error) => error);
  }

  async updateContentSettings(variables) {
    return this.client
      .mutate({
        mutation: data.updateContentSettings,
        variables,
      })
      .then((response) => response.data.updateContentSettings)
      .catch((error) => error);
  }

  async upsertContentAction(
    contentId,
    actionId,
    actionPayload,
    langs,
    type,
    title,
  ) {
    return this.client
      .mutate({
        mutation: data.upsertContentAction,
        variables: { contentId, actionId, actionPayload, langs, type, title },
      })
      .then((response) => response.data.upsertContentAction)
      .catch((error) => error);
  }

  async deleteContentAction(contentId, actionId) {
    return this.client
      .mutate({
        mutation: data.deleteContentAction,
        variables: { contentId, actionId },
      })
      .then((response) => response.data.deleteContentAction)
      .catch((error) => error);
  }

  async updateContentPlugins(contentId, pluginId) {
    return this.client
      .mutate({
        mutation: data.updateContentPlugins,
        variables: { contentId, pluginId },
      })
      .then((response) => response.data.updateContentPlugins)
      .catch((error) => error);
  }

  async relocateContent(childId, formerParentId, newParentId) {
    return this.client
      .mutate({
        mutation: data.relocateContent,
        variables: { childId, formerParentId, newParentId },
      })
      .then((response) => response.data.relocateContent)
      .catch((error) => error);
  }

  async upsertVerificationPolicy({
    contentId,
    verificationRange,
    verificationDueDate,
    setToDraftAtDueDate,
    customRoleId,
    userId,
    lang,
  }) {
    return this.client
      .mutate({
        mutation: data.upsertVerificationPolicy,
        variables: {
          contentId,
          verificationRange,
          verificationDueDate,
          setToDraftAtDueDate,
          customRoleId,
          userId,
          lang,
        },
      })
      .then((response) => response.data.updatedContent.verificationPolicy)
      .catch((error) => error);
  }

  async removeVerificationPolicy(contentId, lang) {
    return this.client
      .mutate({
        mutation: data.removeVerificationPolicy,
        variables: { contentId, lang },
      })
      .then((response) => response.data.removeVerificationPolicy)
      .catch((error) => error);
  }

  async upsertPublicationPolicy({ contentId, publicationDueDate, lang }) {
    return this.client
      .mutate({
        mutation: data.upsertPublicationPolicy,
        variables: {
          contentId,
          publicationDueDate,
          lang,
        },
      })
      .then((response) => response.data.upsertPublicationPolicy)
      .catch((error) => error);
  }

  async removePublicationPolicy(contentId, lang) {
    return this.client
      .mutate({
        mutation: data.removePublicationPolicy,
        variables: { contentId, lang },
      })
      .then((response) => response.data.removePublicationPolicy)
      .catch((error) => error);
  }

  async restoreArchiveContent(archiveId, caseParameters = null) {
    return this.client
      .mutate({
        mutation: data.restoreArchiveContent,
        variables: { archiveId, caseParameters },
      })
      .then((response) => response.data.restoreArchiveContent)
      .catch((error) => error);
  }

  async createSyncContent(variables) {
    return this.client
      .mutate({
        mutation: data.createSyncContent,
        variables,
      })
      .then((response) => response.data.createSyncContent)
      .catch((error) => {
        throw error;
      });
  }

  async syncContent(id, lang) {
    return this.client
      .mutate({
        mutation: data.syncContent,
        variables: { id, lang },
      })
      .then((response) => response.data.syncContent)
      .catch((error) => {
        throw error;
      });
  }

  async unlinkSyncContent(id, lang) {
    return this.client
      .mutate({
        mutation: data.unlinkSyncContent,
        variables: { id, lang },
      })
      .then((response) => response.data.unlinkSyncContent)
      .catch((error) => error);
  }

  async unsetLanguage(contentId, lang) {
    return this.client
      .mutate({
        mutation: data.unsetContentLanguage,
        variables: { contentId, lang },
      })
      .then((response) => response.data.unsetContentLanguage)
      .catch((error) => error);
  }

  async bulkUpdateAttributes(
    contentIds,
    toAdd,
    toRemove,
    isGranularAccessRestrictions,
  ) {
    return this.client
      .mutate({
        mutation: bulkData.bulkUpdateContentAttributes,
        variables: {
          contentIds,
          toAdd,
          toRemove,
          isGranularAccessRestrictions,
        },
      })
      .then((response) => response.data.bulkUpdateContentAttributes)
      .catch((error) => error);
  }

  async bulkUpdateStatus(contentIds, key, value, lang) {
    return this.client
      .mutate({
        mutation: bulkData.bulkUpdateContentStatus,
        variables: { contentIds, key, value, lang },
      })
      .then((response) => response.data.bulkUpdateContentStatus)
      .catch((error) => error);
  }

  async bulkUpdateParameters(contentIds, knowledge, parameters) {
    return this.client
      .mutate({
        mutation: bulkData.bulkUpdateContentParameters,
        variables: { contentIds, knowledge, parameters },
      })
      .then((response) => response.data.bulkUpdateContentParameters)
      .catch((error) => error);
  }

  async bulkArchiveContents(contentIds, softDeleted) {
    return this.client
      .mutate({
        mutation: bulkData.bulkArchiveContents,
        variables: { contentIds, softDeleted },
      })
      .then((response) => response.data.bulkArchiveContents)
      .catch((error) => error);
  }

  async bulkRestoreContents(archiveIds) {
    return this.client
      .mutate({
        mutation: bulkData.bulkRestoreContents,
        variables: { archiveIds },
      })
      .then((response) => response.data.bulkRestoreContents)
      .catch((error) => error);
  }

  async bulkDeleteContents(archiveIds) {
    return this.client
      .mutate({
        mutation: bulkData.bulkDeleteContents,
        variables: { archiveIds },
      })
      .then((response) => response.data.bulkDeleteContents)
      .catch((error) => error);
  }

  async sessionHistorization(payload) {
    this.axiosClient.defaults.headers.common['Content-Type'] =
      'application/json';
    return this.axiosClient.post('/contents/trigger', payload);
  }

  async setContentAccess(id, accessType, lang) {
    return this.client
      .mutate({
        mutation: data.setContentAccess,
        variables: { id, accessType, lang },
      })
      .then((response) => response.data.setContentAccess)
      .catch((error) => error);
  }

  async setPublicDescription(id, description, metaLabel, lang) {
    return this.client
      .mutate({
        mutation: data.setPublicDescription,
        variables: { id, description, metaLabel, lang },
      })
      .then((response) => response.data.setPublicDescription)
      .catch((error) => error);
  }

  async addAccessToken(id, name, expirationDate) {
    return this.client
      .mutate({
        mutation: data.addAccessToken,
        variables: { id, name, expirationDate },
      })
      .then((response) => response.data.addToken)
      .catch((error) => error);
  }

  async revokeAccessToken(id, accessToken) {
    return this.client
      .mutate({
        mutation: data.revokeAccessToken,
        variables: { id, accessToken },
      })
      .then((response) => response.data.revokeToken)
      .catch((error) => error);
  }

  async updateCover(id, url) {
    return this.client
      .mutate({
        mutation: data.updateCover,
        variables: { id, url },
      })
      .then((response) => response.data.setContentCover)
      .catch((error) => error);
  }

  async setRelatedContents(id, relatedContents) {
    return this.client
      .mutate({
        mutation: data.setRelatedContents,
        variables: { id, relatedContents },
      })
      .then((response) => response.data.setRelatedContents)
      .catch((error) => error);
  }

  async getTopContents(knowledgeId) {
    return this.axiosClient
      .get('/top-contents', { params: { knowledgeId } })
      .then((response) => response.data)
      .catch((error) => error);
  }

  // TRIGGES
  async getEligibleActionsFromLocation(location) {
    return this.axiosClient
      .get(`/contents/actions/${location}`)
      .then((response) => response.data)
      .catch((error) => error);
  }

  async triggerContentAction(contentId, actionId) {
    return this.axiosClient.get(`/contents/trigger/${contentId}/${actionId}`);
  }

  // WORKFLOW ACTIONS
  async createWorkflowAction(contentId, workflowAction) {
    return this.client
      .mutate({
        mutation: data.createWorkflowAction,
        variables: { contentId, workflowAction },
      })
      .then((response) => response.data.createContentWorkflowAction)
      .catch((error) => error);
  }

  async updateWorkflowAction(contentId, workflowActionId, workflowAction) {
    return this.client
      .mutate({
        mutation: data.updateWorkflowAction,
        variables: { contentId, workflowActionId, workflowAction },
      })
      .then((response) => response.data.updateContentWorkflowAction)
      .catch((error) => error);
  }

  async deleteWorkflowAction(contentId, workflowActionId) {
    return this.client
      .mutate({
        mutation: data.deleteWorkflowAction,
        variables: { contentId, workflowActionId },
      })
      .then((response) => response.data.deleteContentWorkflowAction)
      .catch((error) => error);
  }

  async orderWorkflowActions(contentId, orderedWorkflowActionIds) {
    return this.client
      .mutate({
        mutation: data.orderWorkflowActions,
        variables: { contentId, orderedWorkflowActionIds },
      })
      .then((response) => response.data.orderContentWorkflowActions)
      .catch((error) => error);
  }

  async getWorkflowActionLibrary() {
    return this.client
      .mutate({
        mutation: data.getWorkflowActionLibrary,
      })
      .then((response) => response.data.workflowActionLibrary)
      .catch((error) => error);
  }

  async addWorkflowActionLibraryItem(item) {
    return this.client
      .mutate({
        mutation: data.addWorkflowActionLibraryItem,
        variables: { item },
      })
      .then((response) => response.data.addWorkflowActionLibraryItem)
      .catch((error) => error);
  }

  async updateWorkflowActionLibraryItem(id, item) {
    return this.client
      .mutate({
        mutation: data.updateWorkflowActionLibraryItem,
        variables: { id, item },
      })
      .then((response) => response.data.updateWorkflowActionLibraryItem)
      .catch((error) => error);
  }
}
