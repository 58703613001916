export class SettingServiceData {
  constructor(client) {
    this.client = client;
  }

  async getCompanyIpRanges() {
    const res = await this.client.get('/settings/company/ip-ranges');
    return res.data;
  }

  async setCompanyIpRanges(ipRanges) {
    const res = await this.client.post('/settings/company/ip-ranges', {
      ipRanges,
    });
    return res.data;
  }

  async deleteCompanyIpRanges() {
    const res = await this.client.delete('/settings/company/ip-ranges');
    return res.data;
  }
}
