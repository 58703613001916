export default () => ({
  /**
   * COMPANY
   */
  adminKnowledges: [],
  focusAdminKnowledgeId: '',

  employees: [],
  employeesCount: 0,
  employeesTableIsLoading: false,
  employeesTableFilters: {
    currentPage: 1,
    totalPageCount: 5,
    filters: [],
    sort: {},
    search: '',
  },

  showModalEmployees: false,
  modalEmployees: [],
  modalEmployeesCount: 0,
  modalEmployeesTableIsLoading: false,
  modalEmployeesTableFilters: {
    currentPage: 1,
    totalPageCount: 10,
    filters: [],
    sort: {},
    search: '',
  },

  pendingEmployees: [],
  pendingEmployeesCount: 0,
  pendingEmployeesTableIsLoading: false,
  pendingEmployeesTableFilters: {
    currentPage: 1,
    totalPageCount: 5,
    filters: [],
    sort: {},
    search: '',
  },

  showModalPendingEmployees: false,
  modalPendingEmployees: [],
  modalPendingEmployeesCount: 0,
  modalPendingEmployeesTableIsLoading: false,
  modalPendingEmployeesTableFilters: {
    currentPage: 1,
    totalPageCount: 10,
    filters: [],
    sort: {},
    search: '',
  },

  userInvitations: [],

  companyHostnames: {},
  groups: [],
  groupsIsLoading: false,
  roles: [],
  rolesIsLoading: false,
  reviewers: {
    users: [],
    roles: [],
  },
  contributors: [],
  permissionsFilter: {},
  adminNotifications: [],

  usersForAdministration: [],
  usersForAdministrationCount: 0,
  usersForAdministrationTableIsLoading: false,
  usersForAdministrationTableQueryParameter: {
    currentPage: 1,
    totalPageCount: 15,
    filters: [],
    sort: {},
    search: '',
  },

  userLabelCategories: [],
  userLabelCategoriesIsLoading: false,

  userExportLoading: {
    pending: false,
    notPending: false,
  },

  companySynonyms: [],

  wsConnectionId: null,
  connectedUsers: {}
});
