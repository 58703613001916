<template>
  <div class="modals-container">
    {{ component }}
    <component
      :is="component"
      @close="initModal"
      @events="sendEvent"
      v-bind="props"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DeleteCaseModal from '@/components/Modals/DangerDeleteCaseModal';
import DeleteContentModal from '@/components/Modals/DeleteContentModal';
import SoftDeleteContentModal from '@/components/Modals/SoftDeleteContentModal';
import DeleteCaseBodyModal from '@/components/Modals/DeleteCaseBodyModal';
import AddTemplateModal from '@/components/Modals/AddTemplateModal';
import CreateTemplateModal from '@/components/Modals/CreateTemplateModal';
import CreateNotificationModal from '@/components/Modals/CreateNotificationModal/CreateNotificationModal';
import ContentVerificationPolicyModal from '@/components/Modals/ContentVerificationPolicyModal/ContentVerificationPolicyModal';
import ContentPublicationPolicyModal from '@/components/Modals/ContentPublicationPolicyModal';
import EditConceptModal from '@/components/Modals/EditConceptModal';
import NewConceptModal from '@/components/Modals/Concept/NewConceptModal';

export default {
  name: 'modals-manager',
  components: {
    DeleteCaseModal,
    DeleteContentModal,
    DeleteCaseBodyModal,
    AddTemplateModal,
    CreateTemplateModal,
    CreateNotificationModal,
    ContentVerificationPolicyModal,
    ContentPublicationPolicyModal,
    EditConceptModal,
    NewConceptModal,
    SoftDeleteContentModal,
  },
  computed: mapState('modalsModule', ['component', 'props']),
  methods: {
    ...mapActions('modalsModule', ['initModal', 'sendEvent']),
  },
  created() {
    if (this.component) this.initModal();
  },
};
</script>
<style lang="scss" scoped>
.modals-container {
  z-index: 2000;
  position: fixed;
}
</style>
