<template>
  <BaseAlertToast
    :should-display="shouldDisplay"
    :type="type"
    :title="title"
    :body="body"
    :icon="icon"
    :cta-text="ctaText"
    @cta-click="handleClick"
    @close="handleClose"
  />
</template>

<script>
import BaseAlertToast from '@/components/BaseAlertToast';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BaseAlertToast,
  },
  data() {
    return {
      versionTypeToDisplayType: { RELEASE: 'success', HOTFIX: 'warning' },
      type: '',
      title: '',
      body: '',
      icon: '',
      ctaText: '',
      closedVersion: '',
    };
  },
  computed: {
    shouldDisplay() {
      return (
        process.env.VUE_APP_VERSION !== this.blobVersion.version &&
        this.blobVersion.isActive &&
        this.closedVersion !== this.blobVersion.version
      );
    },
    langContent() {
      return this.blobVersion.content[this.appLanguage];
    },
    shouldOpenRedirection() {
      return this.blobVersion.type !== 'HOTFIX' && this.isAdmin;
    },
    ...mapState(['appLanguage']),
    ...mapGetters(['blobVersion', 'isAdmin']),
  },
  mounted() {
    this.fetchVersion();
    this.startVersionPolling();
  },
  methods: {
    handleClick() {
      if (this.shouldOpenRedirection) {
        window.open(this.langContent.redirectionUrl, '_blank');
      }
      location.reload();
    },
    handleClose() {
      this.closedVersion = this.blobVersion.version;
    },
    ...mapActions(['startVersionPolling', 'fetchVersion']),
  },
  watch: {
    blobVersion() {
      this.title = this.langContent ? this.langContent.title : '';
      this.type = this.versionTypeToDisplayType[this.blobVersion.type];
      this.body = this.langContent.body;
      this.ctaText = this.langContent.ctaText;
      this.icon = this.blobVersion.icon;
    },
  },
};
</script>

<style lang="scss" scoped></style>
