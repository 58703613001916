<template>
  <div>
    <modal :show.sync="localDisplay">
      <!-- HEADER -->
      <div class="header-card">
        <div class="title">{{ $t('knowledge.templates.import.title') }}</div>
        <div class="divider"></div>
        <div class="description">
          {{ $t('knowledge.templates.import.description') }}
        </div>
      </div>
      <div class="templates-search">
        <div class="search-wrapper">
          <div><font-awesome-icon :icon="['fas', 'search']" /></div>
          <input
            v-model="search"
            :placeholder="$t('knowledge.templates.import.placeholder')"
          />
        </div>
      </div>
      <!-- BODY -->
      <div
        v-if="!searchTemplates.length"
        class="templates align-items-center"
        v-loading="isLoading"
      >
        <div class="templates-view-empty">
          <div class="title">
            {{ $t('knowledge.templates.empty-state.title') }}
          </div>
          <div class="icon">
            <img src="~assets/empty-states/empty-state-templates.png" />
          </div>
          <div class="description">
            {{ $t('knowledge.templates.empty-state.description') }}
          </div>
          <div>
            <el-button
              v-if="templates.length"
              type="primary"
              size="small"
              @click="search = ''"
            >
              {{ $t('knowledge.templates.empty-state.cta-search') }}</el-button
            >
          </div>
        </div>
      </div>
      <div v-else class="templates" v-loading="isLoading">
        <div
          v-for="template in searchTemplates"
          :key="template.id"
          class="d-flex"
          :class="{ disabled: !canImportTemplate(template) }"
        >
          <div class="template" @click.prevent="handleChoice(template)">
            <div class="template-info">
              <div
                class="template-icon"
                :style="iconStyle(template.content.type)"
              >
                <div class="template-icon-inner">
                  <IconWrapper
                    :icon="items[template.content.type].icon"
                    :size="20"
                    class="icon-container"
                  />
                </div>
              </div>
              <div class="template-label">
                <div class="name">
                  {{ template.content.label }}
                </div>

                <TextOverflowTooltip
                  class="description"
                  :area="true"
                  :text="
                    template.description ||
                    $t('knowledge.templates.import.empty-description')
                  "
                />
              </div>
            </div>
          </div>
          <el-tooltip
            placement="left"
            class="mx-2 mt-3"
            v-if="importWarning(template)"
            :content="importWarning(template)"
          >
            <font-awesome-icon
              :icon="['fad', 'exclamation-triangle']"
              class="warning-icon"
          /></el-tooltip>
        </div>
      </div>
      <div class="modal-footer">
        <el-button
          @click="backTemplates"
          v-if="back"
          type="text"
          size="small"
          >{{ $t('knowledge.templates.import.back') }}</el-button
        >
        <el-button @click="localDisplay = false" size="small">{{
          $t('knowledge.templates.import.close')
        }}</el-button>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import articleIcon from '@/assets/article-icon-v2.1.svg';
import diagIcon from '@/assets/diag-icon-v2.1.svg';
import IconWrapper from '@/components/IconWrapper';
import TextOverflowTooltip from '@/components/Commons/TextOverflowTooltip.vue';

export default {
  name: 'add-template-modal',
  props: {
    display: Boolean,
    back: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IconWrapper,
    TextOverflowTooltip,
  },
  async mounted() {
    this.isLoading = true;
    await this.getTemplates();
    this.isLoading = false;
  },
  computed: {
    searchTemplates() {
      if (!this.search) return this.templates;
      return this.templates.filter(
        ({ content, description }) =>
          content.label.toUpperCase().includes(this.search.toUpperCase()) ||
          description.toUpperCase().includes(this.search.toUpperCase()),
      );
    },
    ...mapGetters('knowledgeModule', [
      'templates',
      'editingLanguage',
      'focusKnowledgeLanguages',
    ]),
  },
  data() {
    return {
      isLoading: false,
      localDisplay: this.display,
      search: '',
      items: {
        Diagnostic: {
          icon: {
            iconType: 'url',
            value: diagIcon,
            color: null,
          },
          color: '#0081F91A',
        },
        Article: {
          icon: {
            iconType: 'url',
            value: articleIcon,
            color: null,
          },
          color: '#FCBF491A',
        },
      },
    };
  },
  methods: {
    iconStyle(type) {
      return `background-color: ${this.items[type].color}`;
    },
    handleChoice(choice) {
      this.$emit('events', {
        eventName: 'choice',
        eventData: choice,
      });
      this.localDisplay = false;
    },
    backTemplates() {
      this.$emit('events', {
        eventName: 'back',
        eventData: null,
      });
      this.localDisplay = false;
    },
    canImportTemplate({ content }) {
      const { setLanguages } = content;
      if (!setLanguages.length) return false;

      const hasFocusedLanguages = setLanguages
        .map((lang) => this.focusKnowledgeLanguages.includes(lang))
        .filter(Boolean);

      const hasEditingLanguage = content.setLanguages.includes(
        this.editingLanguage,
      );

      return hasFocusedLanguages && hasEditingLanguage;
    },
    importWarning({ content }) {
      if (this.canImportTemplate({ content })) return '';

      if (content.setLanguages.includes('default')) {
        return this.$t('knowledge.templates.import.warning.no-multi');
      }

      if (this.editingLanguage === 'default') {
        return this.$t('knowledge.templates.import.warning.is-multi');
      }

      return this.$t('knowledge.templates.import.warning.no-lang');
    },
    ...mapActions('knowledgeModule', ['getTemplates']),
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .modal-body {
  padding: 16px;
}

.header-card {
  padding: 12px;
  background-color: $grey-1-mayday;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .divider {
    content: '';
    height: 1px;
    width: 80%;
    background-color: $grey-4-mayday;
    margin-bottom: 0px;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}

.templates {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  min-height: 216px;
  max-height: 216px;
  overflow: auto;
}

.empty-templates {
  padding: 8px;
  text-align: center;
}

.templates-search {
  width: 100%;
  padding-top: 8px;
  .search-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid $grey-2-mayday;
    padding: 8px;
    font-size: 14px;
    input {
      width: 100%;
      border: none;
      outline: none;
    }
  }
}
.disabled {
  cursor: not-allowed;

  .template {
    pointer-events: none;

    opacity: 0.5;
  }
}

.template {
  cursor: pointer;

  display: flex;
  align-items: center;

  width: 100%;
  min-width: 0;
  background-color: transparent;
  &:hover {
    background-color: $grey-1-mayday;
    border-radius: 4px;
  }

  .template-info {
    display: flex;
    min-width: 0;
    padding: 8px;
    width: 100%;
    .template-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px !important;
      height: 32px;
      margin-right: 12px;
      border-radius: 4px;

      .template-icon-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 4px;
      }
      :deep() .logo-container {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    }

    .template-label {
      min-width: 0;
      width: calc(100% - 44px);
      .name {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
      }

      .description {
        margin-top: 4px;
        font-size: 12px;
        color: $grey-6-mayday;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  gap: 8px;
}

.templates-view-empty {
  width: 400px;
  height: 216px;
  display: flex;
  flex-direction: column;
  padding: 0 42px;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .icon {
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description {
    font-size: 12px;
    color: $grey-7-mayday;
  }
}

.warning-icon {
  font-size: 14px;
}
</style>
